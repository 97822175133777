import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ProductCard from 'components/general/ProductCard.js';
import ProductCardLoading from 'components/general/ProductCardLoading.js';
import { ReactComponent as IconSmallArrowLeft } from 'components/icons/small-arrow-left.svg';
import { browserName } from 'react-device-detect';
import './HorizontalScrollWrapper.scss';

const HorizontalScrollWrapper = ({
  products,
  placeholder = null,
}) => {
  const hsw = useRef();
  const scrollBar = useRef();
  const buttonLeft = useRef();
  const buttonRight = useRef();

  let scrollBarStyle = {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: 'grey',
    opacity: 0,
    cursor: 'pointer',
  };

  const handleScroll = (event) => {
    if (event) event.preventDefault();

    let scrollState = {};

    scrollState.viewableWidth = hsw.current.offsetWidth;
    scrollState.scrollWidth = hsw.current.scrollWidth;
    scrollState.maximalScrollPosition = hsw.current.scrollWidth - hsw.current.offsetWidth;
    scrollState.scrollPosition = hsw.current.scrollLeft;
    scrollState.percentageScrolled = hsw.current.scrollLeft / scrollState.maximalScrollPosition;

    scrollState.scrollBarWidth = scrollState.viewableWidth**2 / scrollState.scrollWidth;
    scrollState.scrollBarLeft = scrollState.percentageScrolled * (scrollState.viewableWidth - scrollState.scrollBarWidth);
    
    scrollBar.current.style.opacity = scrollState.maximalScrollPosition > 0 ? 0.5 : 0;
    scrollBar.current.style.width = `${scrollState.scrollBarWidth}px`;
    scrollBar.current.style.left = `${scrollState.scrollBarLeft}px`;

    if (buttonLeft.current) {
      if(scrollState.percentageScrolled == 0 || scrollState.maximalScrollPosition == 0) buttonLeft.current.style.display = 'none';
      else buttonLeft.current.style.display = 'flex';
    }

    if (buttonRight.current) {
      if(scrollState.percentageScrolled == 1 || scrollState.maximalScrollPosition == 0) buttonRight.current.style.display = 'none';
      else buttonRight.current.style.display = 'flex';
    }
  };
  useEffect(() => {
    handleScroll();
  });

  const [dragStartX, setDragStartX] = useState(0);
  const [startPercentageScrolled, setStartPercentageScrolled] = useState(0);

  const handleDragStart = (event) => {
    const maximalScrollPosition = hsw.current.scrollWidth - hsw.current.offsetWidth;
    const percentageScrolled = hsw.current.scrollLeft / maximalScrollPosition;

    setDragStartX(event.screenX);
    setStartPercentageScrolled(percentageScrolled);

    let pic = new Image();
    pic.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'; //transparent gif, resolves issue with Safari that otherwise does not allow dragging
    pic.style.visibility = 'hidden';
    event.dataTransfer.setDragImage(pic, 0, 0);
    event.dataTransfer.effectAllowed = 'none';
    // event.dataTransfer.setDragImage(clone, 0, 0);
  };

  const handleDrag = (event) => {
    if(event.screenX != 0) {
      const draggedX = event.screenX - dragStartX;
      const percentageDragged = draggedX / hsw.current.offsetWidth;
      
      hsw.current.scrollTo({
        top: 0,
        left: (startPercentageScrolled + percentageDragged) * hsw.current.scrollWidth,
      });
    }
  };

  return (
    <div className="hsw-wrapper" onDragOver={(event) => browserName === 'Firefox' && handleDrag(event)}>
      <div ref={hsw} className="hsw hide-scrollbar" onScroll={handleScroll}>
        {products === null ?
          <div className="product-wrapper">
            <ProductCardLoading />
          </div>
          :
          <>
            {products && products.length > 0 ?
              products.map((product) => (product && product.objectID) && (
                <div key={product.objectID} className="product-wrapper">
                  <ProductCard product={product} shadow forceBorder />
                </div>
              ))
              :
              <>{
                placeholder && <div style={{height: '300px'}} className="d-flex align-items-center">
                  {placeholder}
                </div>
              }</>
            }
          </>
        }
      </div>

      <div ref={buttonLeft} className="showMore left" onClick={() => hsw.current.scrollBy({
        top: 0,
        left: -300,
        behavior: 'smooth'
      })}>
        <button>
          <IconSmallArrowLeft />
        </button>
      </div>
      <div ref={buttonRight} className="showMore right" onClick={() => hsw.current.scrollBy({
        top: 0,
        left: 300,
        behavior: 'smooth'
      })}>
        <button>
          <IconSmallArrowLeft />
        </button>
      </div>
      <div ref={scrollBar} className="scrollBar" style={scrollBarStyle} draggable onDragStart={handleDragStart} onDrag={(event) => browserName !== 'Firefox' && handleDrag(event)}></div>
    </div>
  );
};

HorizontalScrollWrapper.propTypes = {
  products: PropTypes.array,
  placeholder: PropTypes.any,
};

export default HorizontalScrollWrapper;