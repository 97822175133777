import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from 'features/authSlice';
import {useAlgoliaProductIndex} from 'components/algolia/WithAlgolia';
import CartItem from 'components/general/Cart/CartItem';
import HorizontalScrollWrapper from 'components/algolia/HorizontalScrollWrapper';
import LoadingBottle from 'components/general/LoadingBottle';
import { ReactComponent as IconClose } from 'components/icons/close.svg';
import { cartSelectors, removeFromCurrentCart, getSimmilarWines, cartActions } from 'features/cartSlice';
import {FormattedMessage} from 'react-intl';
import { ReactComponent as IconInfo } from 'components/icons/info.svg';
import Modal from '../Modal';

const CartBodyOpen = ({
  hasItems,
  items,
  // itemsIds = null,
  products,
  total,
  deposit,
  modalBodyRef,
}) => {
  const dispatch = useDispatch();
  const productIndex = useAlgoliaProductIndex();
  const productIndexReady = productIndex && productIndex.appId;
  const [openMBW, setOpenMBW] = useState(false);

  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const simmilars = useSelector(cartSelectors.simmilars);
  const simmilarsRequest = useSelector(cartSelectors.simmilarsRequest);
  useEffect(() => {
    if (simmilarsRequest.status === 'idle' && items != null) {
      dispatch(getSimmilarWines({
        authed: isLoggedIn,
        ids: items.map(item => item.wineId),
      }));
    }
  }, [simmilarsRequest.status, items]);
  useEffect(() => {
    dispatch(cartActions.resetSimmilars());
  }, [items]);

  const [simmilarsProducts, setSimmilarsProducts] = useState(null);
  useEffect(() => {
    if (productIndexReady && simmilars?.length != simmilarsProducts?.length) {
      productIndex.getObjects(simmilars || []).then((result) => {
        setSimmilarsProducts(result.results);
      });
    } else if (!simmilars || simmilars.length == 0) setSimmilarsProducts([]);
  }, [productIndexReady, simmilars]);
  useEffect(() => {
    setSimmilarsProducts([]);
  }, [productIndex]);

  return (
    <div className="p-3">
      <div className="my-4">
        {hasItems ? items.map(item => (
          <div key={item.id} className="small-product mb-4">
            {products.find(product => product && product.objectID && product.objectID == item.wineId) ?
              <CartItem
                quantity={item.quantity}
                product={products.find(product => product && product.objectID == item.wineId)}
                showImage={true}
                changeable={true}
                archived={item && item.issues.includes('ARCHIVED')}
              />
              : 
              <div className="d-flex justify-content-between align-items-center">
                <LoadingBottle />
                <span onClick={() => dispatch(removeFromCurrentCart({
                  wineId: item.wineId,
                }))}>
                  <IconClose style={{width: '14px', height: '14px'}} />
                </span>
              </div>
            }
          </div>
        )) : 
          <i>
            <FormattedMessage
              id="CartBody.NoProducts"
              values={{ts: Date.now()}}
            />

          </i>
        }
      </div>
      <hr />
      <div className="d-flex justify-content-between">
        <span>
          <FormattedMessage
            id="CartBody.Zwischensumme"
            values={{ts: Date.now()}}
          />

        </span>
        <span className="mono">
          CHF {total.toFixed(2)}
        </span>
      </div>
      <hr />
      <div className="d-flex justify-content-between">
        <span>
          <FormattedMessage
            id="CartBody.Gebindepfand"
            values={{ts: Date.now()}}
          />
        </span>
        <span className="mono">CHF {deposit.toFixed(2)}</span>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <span className="d-flex align-items-center">
          <FormattedMessage
            id="CartBody.MBW"
            values={{ts: Date.now()}}
          />
          <IconInfo onClick={() => setOpenMBW(true)} />
          <Modal
            disableBodyScrollOnOpen={false}
            name="mbw info modal confirm page"
            open={openMBW}
            setOpen={setOpenMBW}
            position="middle-side"
            size="tiny"
            parentBody={modalBodyRef}
            title={<>
              <IconClose className="icon ms-auto" onClick={() => setOpenMBW(false)}/>
            </>}
            body={<div className="d-flex flex-column justify-content-center align-items-center text-center px-5">
              <IconInfo style={{height: '50px', width: '50px'}} />
              <h6 className="my-4">Mindestbestellwert</h6>
              <p>Der Mindestbestellwert wird verrechnet sobald eine Bestellung nicht CHF 300 erreicht.</p>
            </div>}
          />
        </span>
        <span className="mono">CHF {(total >= 300 ? 0 : 25).toFixed(2)}</span>
      </div>
      {total < 300 && <>
        <span className="red">Es fehlen noch CHF {(300 - total).toFixed(2)} bis der Zuschlag entfällt</span>
      </>}
      <div className="d-flex justify-content-between mt-2">
        <span className="d-flex align-items-center">
          <FormattedMessage
            id="CartBody.Lieferkostenpauschale"
            values={{ts: Date.now()}}
          />
        </span>
        <span className="mono">CHF {(10).toFixed(2)}</span>
      </div>
      <hr />
      <div className="d-flex justify-content-between">
        <span>
          <FormattedMessage
            id="CartBody.Total"
            values={{ts: Date.now()}}
          />

        </span>
        <span className="total mono">
          CHF {(total + deposit + (total >= 300 ? 0 : 25) + 10).toFixed(2)}
        </span>
      </div>
      <div className="mt-5">
        {simmilarsRequest.status == 'loading' || simmilarsRequest.status == 'error' || simmilars?.length > 0 ?
          <h5 style={{color:'#AF1430',fontWeight:500}}>
            <FormattedMessage
              id="CartBody.Kunden"
              values={{ts: Date.now()}}
            />
          </h5>
          :
          <></>
        }
        <HorizontalScrollWrapper products={simmilarsProducts ? simmilarsProducts : []} />
      </div>
    </div>
  );
};

CartBodyOpen.propTypes = {
  hasItems: PropTypes.bool.isRequired,
  items: PropTypes.array,
  itemsIds: PropTypes.array,
  products: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  deposit: PropTypes.number.isRequired,
  modalBodyRef: PropTypes.any,
};

export default CartBodyOpen;
