import React, {useEffect, useState} from 'react';

import {ReactComponent as IconLogo} from 'components/icons/logo.svg';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect, useLocation} from 'react-router-dom';

import {authActions, authSelectors} from '../../features/authSlice';
import {userActions} from '../../features/userSlice';
import {reset} from 'features/searchSlice';

import './LoginPage.scss';
import {FormattedMessage} from 'react-intl';
import LoadingBottle from 'components/general/LoadingBottle';
import {authApi} from '../../api/authApi';


const LoginPage = () => {
  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {

    const getUserInformation = async (token) => {

      const userInfo = await authApi.getUserInformation(token);
      const userRoles = userInfo.roles;

      dispatch(authActions.tokenLogin({token: query.get('token'), userRoles: userRoles}));
      dispatch(userActions.setSoldTo(query.get('soldTo')));
      dispatch(userActions.fetchOutlets());

    };

    if (query.get('token')) {
      const token = query.get('token');
      getUserInformation(token)
        .catch(e => console.error(e));
    }

  }, [query]);

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);

    dispatch(authActions.login({username, password}))
      .then(loginResponse => {
        if (loginResponse.payload) {
          const user = extractUserFromLoginResponse(loginResponse);
          dispatch(userActions.login(user));
          dispatch(reset());
          setLoading(false);
        } else {
          setError('Kundennummer oder Passwort falsch.');
          setLoading(false);
        }
      });
  };

  return (
    <>{
      isLoggedIn ?
        <Redirect to={{pathname: '/'}}/>
        :
        <div className="login-wrapper">
          <form onSubmit={(event) => handleLogin(event)}>
            <IconLogo/>
            <p>
              <FormattedMessage
                id="LoginPage.FsAccount"
                values={{
                  ts: Date.now(),
                  breakingLine: <br/>
                }}
              />
            </p>

            <label>
              <FormattedMessage
                id="LoginPage.Kundennummer"
                values={{ts: Date.now()}}
              />

            </label>
            <div className="input-wrapper">
              <input
                className="input"
                type="text"
                value={username}
                onChange={(ev) => setUsername(ev.target.value)}/>
            </div>

            <label>
              <FormattedMessage
                id="LoginPage.Password"
                values={{ts: Date.now()}}
              />

            </label>
            <div className="input-wrapper">
              <input
                className="input"
                type="password"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}/>
              <div className="error">{error}</div>
            </div>

            <button
              type="submit"
              disabled={!username || !password || loading}
            >
              {loading ?
                <LoadingBottle />
                :
                <FormattedMessage
                  id="LoginPage.Anmelden"
                  values={{ts: Date.now()}}
                />
              }
            </button>

            <a href="https://fscockpit.fs-apps.ch/login/resetPassword" className="mt-3">
              <FormattedMessage
                id="LoginPage.PassVerg"
                values={{ts: Date.now()}}
              />
            </a>

            <Link to="/register" className="mt-3">
              <FormattedMessage id="LoginPage.Registrieren" />
            </Link>
          </form>
        </div>
    }</>
  );
};

function extractUserFromLoginResponse(loginResponse) {
  return {
    username: loginResponse.payload.username
  };
}

export default LoginPage;
