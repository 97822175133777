import React from 'react';
import {FormattedMessage} from 'react-intl';
import { ReactComponent as IconLogo } from 'components/icons/logo.svg';
import './ShipToSwitcher.scss';
import ShipToSwitcherList from './ShipToSwitcherList';

export function ShipToSwitcher() {
  return (
    <div className="shipTo-switcher">
      <IconLogo />
      <p>
        <FormattedMessage
          id="ShipToSwitcher.Welcome"
        />

      </p>
      <ShipToSwitcherList className="last-child-border" />
    </div>
  );
}

export default ShipToSwitcher;