import {wineAppPublicClient} from './wineAppClient';
import {wineAppAuthedClient} from './wineAppClient';

function history() {
  return wineAppAuthedClient.request({
    method: 'GET',
    url: '/suggest/searchhistory/',
    responseType: 'json'
  }).then(response => response.data);
}

function addToHistory(item) {
  return wineAppAuthedClient.request({
    method: 'POST',
    url: '/suggest/searchhistory/',
    responseType: 'json',
    data: {
      term: item,
    }
  }).then(response => response.data);
}

function deleteFromHistory(item) {
  return wineAppAuthedClient.request({
    method: 'DELETE',
    url: '/suggest/searchhistory/',
    responseType: 'json',
    data: {
      term: item,
    }
  }).then(response => response.data);
}

function recommendations(isLoggedIn) {
  if(isLoggedIn)
    return wineAppAuthedClient.request({
      method: 'GET',
      url: '/suggest/recommendation/',
      responseType: 'json'
    }).then(response => response.data);
  else
    return wineAppPublicClient.request({
      method: 'GET',
      url: '/suggest/recommendation/',
      responseType: 'json'
    }).then(response => response.data);
}

export const searchApi = {
  history,
  addToHistory,
  deleteFromHistory,
  recommendations,
};