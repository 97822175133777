import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {userApi} from '../api/userApi';

const selectors = {
  to: (state) => state.choseCustomer.to,
  customer: (state) => state.choseCustomer.customer,
  customerPrev: (state) => state.choseCustomer.customerPrev,
  address: (state) => state.choseCustomer.address,
  checkCustomerInputRequest: (state) => state.choseCustomer.requests.checkCustomerInput,
};

export const checkCustomerInput = createAsyncThunk(
  'address/',
  async (body) => userApi.getCustomerAddress(body.soldTo)
);

const {actions, reducer} = createSlice({
  name: 'choseCustomer',
  initialState: {
    to: null,
    customer: null,
    customerPrev: null,
    address: null,
    requests: {
      checkCustomerInput: {
        loading: false,
        error: null
      }
    }
  },
  reducers: {
    setTo: (state, action) => {
      state.to = action.payload;
    },
    setCustomer: (state, action) => {
      state.customerPrev = state.customer;
      state.customer = action.payload;
    },
    reset: (state) => {
      state.to = '/';
      state.customer = '';
      state.customerPrev = '';
      state.requests.checkCustomerInput = {
        loading: false,
        error: null
      };
    },
  },
  extraReducers: {
    [checkCustomerInput.pending]: (state) => {
      state.requests.checkCustomerInput.loading = true;
      state.requests.checkCustomerInput.error = null;
    },
    [checkCustomerInput.fulfilled]: (state, action) => {
      state.requests.checkCustomerInput.loading = false;
      state.address = action.payload;

    },
    [checkCustomerInput.rejected]: (state, action) => {
      state.requests.checkCustomerInput.loading = false;
      state.requests.checkCustomerInput.error = action.error.message;
    },
  }
});


export {actions as choseCustomerActions};
export {reducer as choseCustomerReducer};
export {selectors as choseCustomerSelectors};