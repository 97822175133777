const globals = {
  zenDeskApi: null,
  zenDeskDefaultBtnHidden: false
};

export function getZenDesk() {
  if (globals.zenDeskApi == null) {
    console.error('zen desk is not initialized');
    return;
  }

  return globals.zenDeskApi;
}

/**
 * load zenDesk script by adding a <script> tag to body and provide some helper methods
 */
export function initZenDesk(zESettings) {

  if (globals.zenDeskApi != null) {
    console.error('zen desk is already initialized');
    return;
  }

  const zenDeskPromise = new Promise(resolve => {
    // set zESettings (https://developer.zendesk.com/api-reference/widget/settings/)
    window.zESettings = zESettings;

    // load zenDesk script by adding a <script> tag
    const zenDeskScriptTag = document.createElement('script');
    zenDeskScriptTag.id = 'ze-snippet';
    zenDeskScriptTag.type = 'text/javascript';
    zenDeskScriptTag.onload = () => {
      window.zE('webWidget:on', 'close', function () {
        if (globals.zenDeskDefaultBtnHidden) {
          window.zE('webWidget', 'hide');
        }
      });
      resolve({
        scriptTag: zenDeskScriptTag
      });
    };
    zenDeskScriptTag.src = 'https://static.zdassets.com/ekr/snippet.js?key=3cbe3707-9493-47f1-9a5e-5eb5cc3762c2';
    document.body.appendChild(zenDeskScriptTag);
  });

  globals.zenDeskApi = {
    setShowDefaultBtn(showDefaultBtn) {
      const operation = showDefaultBtn ? 'show' : 'hide';
      globals.zenDeskDefaultBtnHidden = !showDefaultBtn;
      zenDeskPromise.then(() => {
        window.zE('webWidget', operation);
      });
    },
    showChat: () => {
      zenDeskPromise.then(() => {
        window.zE('webWidget', 'show');
        window.zE('webWidget', 'open');
      });
    },
    unload: () => {
      zenDeskPromise.then(zenDesk => {
        document.body.removeChild(zenDesk.scriptTag);
      });
    }
  };

}
