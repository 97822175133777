
// This functions work with useEffect and can add an event listener
// when a component is mounted and unmounted

export const callOnClickOutside = (ref, callback) => {
  const outsideClick = (ev) => ref.current.contains(ev.target) || callback();

  // add when mounted
  document.addEventListener('mouseup', outsideClick);

  // return function to be called when unmounted
  return () => {
    document.removeEventListener('mouseup', outsideClick);
  };
};

export const ESC = 27;
export const ENTER = 13;

export const callOnKey = (keyCode, callback) => {
  const escFunction = (ev) => ev.keyCode === keyCode && callback();

  // add when mounted
  document.addEventListener('keyup', escFunction, false);

  // return function to be called when unmounted
  return () => {
    document.removeEventListener('keyup', escFunction, false);
  };
};