import {wineAppAuthedClient, wineAppPublicClient} from './wineAppClient';

function getAlgoliaCredentials(loggedIn) {
  const client = loggedIn ? wineAppAuthedClient : wineAppPublicClient;
  return client.request({
    method: 'GET',
    url: '/config/algolia',
    responseType: 'json'
  }).then(response => response.data);
}

export const algoliaCredentialsApi = {
  getAlgoliaCredentials
};
