import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cartActions,
  fetchCurrentCart,
  submitCurrentCart,
  removeFromCurrentCart,
  cartSelectors
} from 'features/cartSlice';
import { ReactComponent as IconCart } from 'components/icons/cart.svg';
import { ReactComponent as IconArrowLeft } from 'components/icons/arrow-left.svg';
import { ReactComponent as IconLogo } from 'components/icons/logo.svg';
import { Link, useLocation } from 'react-router-dom';
import Modal from 'components/general/Modal';
import './Cart.scss';
import 'react-datepicker/dist/react-datepicker.css';
import {useAlgoliaProductIndex} from 'components/algolia/WithAlgolia';
import CartBodyOpen from 'components/general/Cart/CartBodyOpen';
import CartBodyConfirm from 'components/general/Cart/CartBodyConfirm';
import LoadingBottle from 'components/general/LoadingBottle';
// import {userSelectors} from 'features/userSlice';
import {FormattedMessage} from 'react-intl';
import { useAlert } from 'react-alert';

export function Cart() {
  let location = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [location]);

  const modal = useRef();
  const modalBodyRef = useRef();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const alert = useAlert();

  const items = useSelector(cartSelectors.items);
  const itemsIds = useSelector(cartSelectors.itemsIds);
  const hasItems = useSelector(cartSelectors.hasItems);
  const fetchCurrentCartRequest = useSelector(cartSelectors.fetchCurrentCartRequest);
  const submitCurrentCartRequest = useSelector(cartSelectors.submitCurrentCartRequest);
  const cartStatus = useSelector(cartSelectors.cartStatus);

  const deliveryDate = useSelector(state => state.cart.deliveryDate);
  // const soldTo = useSelector(userSelectors.soldTo);
  // const comment = useSelector(cartSelectors.comment);

  const productIndex = useAlgoliaProductIndex();
  const productIndexReady = productIndex && productIndex.appId;
  const [products, setProducts] = useState([]);

  const [total, setTotal] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [cartIsAllGood, setCartIsAllGood] = useState(false);
  const dot = (a, b) => a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);

  useEffect(() => {
    if (fetchCurrentCartRequest.status === 'idle') {
      dispatch(fetchCurrentCart());
    }
  });

  useEffect(() => {
    if (submitCurrentCartRequest.status === 'error') {
      alert.info(`Fehler beim Absenden des Warenkorbs: ${submitCurrentCartRequest.msg}`);
    }
  }, [submitCurrentCartRequest.status]);

  useEffect(() => {
    if (productIndexReady && products.length != itemsIds.length ) {
      productIndex.getObjects(itemsIds).then((result) => {
        setProducts(result.results);
      });
    }
  }, [productIndex, products, itemsIds]);
  useEffect(() => {
    setProducts([]);
  }, [productIndex]);

  useEffect(() => {
    if (hasItems && products.length > 0 && items.length == products.length) {
      const quantitys = items.map(item => item ? item.quantity : 0);
      const pricesPerUnit = products.map(item => item ? item.sale_price_per_unit : 0);
      const depositPerUnit = products.map(item => item ? item.deposit : 0);
      const ones = products.map(item => item ? 1 : 0);
      const outOfStock = products.map(item => item ? item.outOfStock : false);
      const archived =  items.map(item => item && item.issues ? item.issues.includes('ARCHIVED') : false);
      setTotal(dot(quantitys, pricesPerUnit));
      setDeposit(dot(quantitys, depositPerUnit));
      setCartIsAllGood(dot(ones, outOfStock) == 0 && dot(ones, archived) == 0);
    } else {
      setTotal(0);
      setDeposit(0);
    }
  }, [hasItems, items, products]);

  useEffect(() => {
    if (!open) {
      dispatch(cartActions.setCartStatus({
        cartStatus: 'open'
      }));
      dispatch(cartActions.resetDeliveryDates());
    }
  }, [open]);

  useEffect(() => {
    modalBodyRef.current.scrollTo({
      top: 0,
    });
  }, [cartStatus]);

  const backToHome = () => {
    setOpen(false);
    dispatch(cartActions.reset());
  };

  const removeFromCurrentRequest = useSelector(cartSelectors.removeFromCurrentRequest);
  const handleDeleteCart = () => {
    items.map(item => {
      dispatch(removeFromCurrentCart({
        wineId: item.wineId,
      }));
    });
  };

  return (
    <>
      <span className="cart" onClick={() => items !== null && setOpen(true)}>
        {items === null ?
          <LoadingBottle />
          :
          <IconCart />
        }
        
        {hasItems &&
          <span className="cart-badge">
            {items.reduce((prev, curr) => prev + curr.quantity, 0)}
          </span>
        }
      </span>

      <Modal
        name="cart"
        modal={modal}
        open={open}
        setOpen={setOpen}
        position="right-side"
        size="medium"
        modalBodyRef={modalBodyRef}
        title={
          {
            'open':
              <>
                <IconArrowLeft className="me-3" onClick={() => setOpen(false)} />
                <FormattedMessage
                  id="Cart.Warenkorb"
                />
                {removeFromCurrentRequest.status === 'loading' ?
                  <div className="ms-auto">
                    <LoadingBottle />
                  </div>
                  :
                  <i className="ms-auto text-muted cursor-pointer" onClick={() => handleDeleteCart()}>
                    löschen
                  </i>
                }
              </>,
            'confirm':
              <>
                <IconArrowLeft className="me-3" onClick={() => dispatch(cartActions.setCartStatus({
                  cartStatus: 'open'
                }))} />
                Checkout
              </>,
            'sent': <></>
          }[cartStatus]
        }
        body={
          {
            'open':
              <CartBodyOpen
                hasItems={hasItems}
                items={items}
                itemsIds={itemsIds}
                products={products}
                total={total}
                deposit={deposit}
                setOpen={setOpen}
                modalBodyRef={modalBodyRef}
              />,
            'confirm':
              <CartBodyConfirm
                hasItems={hasItems}
                items={items}
                products={products}
                total={total}
                deposit={deposit}
                setOpen={setOpen}
                modalBodyRef={modalBodyRef}
              />,
            'sent':
              <div className="p-3 confirmation-page">
                <IconLogo />
                <p>
                  <FormattedMessage
                    id="Cart.Thanks"
                    values={{
                      b(fragments) { return <b>{fragments}</b>; },
                      breakingLine: <br />
                    }}
                  />
                </p>
                <Link className="btn btn-primary" to="/" onClick={() => backToHome()}>
                  <FormattedMessage
                    id="Cart.Startpage"
                  /> 
                </Link>
              </div>
          }[cartStatus]
        }
        foot={
          {
            'open':
              <>
                {!cartIsAllGood && hasItems && <span>
                  Bitte entferne ausverkaufte oder nicht mehr verfügbare Weine aus dem Warenkorb um mit der Bestellung fortzufahren.
                </span>}
                <button
                  className="primary full"
                  disabled={!hasItems || !cartIsAllGood}
                  onClick={() => dispatch(cartActions.setCartStatus({
                    cartStatus: 'confirm'
                  }))}>
                  <FormattedMessage
                    id="Cart.ContinueToOrder"
                  /> 
                </button>
              </>,
            'confirm':
              <>
                {!cartIsAllGood && hasItems && <span>
                  Bitte entferne ausverkaufte oder nicht mehr verfügbare Weine aus dem Warenkorb um mit der Bestellung fortzufahren.
                </span>}
                <button
                  className="primary full"
                  disabled={!hasItems || !cartIsAllGood || !deliveryDate || submitCurrentCartRequest.status === 'loading'}
                  onClick={() => {
                    dispatch(submitCurrentCart());
                  }}>
                  {submitCurrentCartRequest.status === 'loading' ?
                    <LoadingBottle />
                    :
                    <FormattedMessage
                      id="Cart.Order"
                    />
                  }
                </button>
              </>,
            'sent': <></>
          }[cartStatus]
        }
      />
    </>
  );
}

export default Cart;