import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setQuery } from 'features/searchSlice';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { CustomClearRefinements } from './CustomClearRefinements';
import './CustomCurrentRefinements.scss';
import { useIntl } from 'react-intl';

const CurrentRefinements = ({ items, refine, createURL, placeholder = false }) => {
  const intl = useIntl();
  const query = useSelector(state => state.search.query);
  const dispatch = useDispatch();

  const replaceName = (name) => {
    let tmp = name;
    const replacements = {
      sale_price_per_bottle: intl.formatMessage({id: "Refinement.Preis"}),
      alcoholContent: intl.formatMessage({id: 'Refinement.Alkoholgehalt'}),
      taste: intl.formatMessage({id: 'Refinement.Schweregrad'}),
      sweetness: intl.formatMessage({id: 'Refinement.Süssegrad'}),
      true: intl.formatMessage({id: "Refinement.ageingContainer.oak"}),
      false: intl.formatMessage({id: "Refinement.ageingContainer.steel"}),
    };

    Object.entries(replacements).map(([key, item]) => {
      tmp = tmp.replace(key, item);
    });

    return tmp;
  };
  
  return (
    <div className={items.length || query !== '' || placeholder ? '' : 'd-none'}>
      <div className="d-flex refinement-list p-2 mb-3">
        <div className="mr-auto flex-grow-1">
          {query !== '' &&
            <span className="badge rounded-pill refinement-pill m-1">
              <a className="cursor-pointer" onClick={() => dispatch(setQuery({query: ''}))}>
                Suche: {query}
              </a>
            </span>
          }
          {items.map(item => (
            <span key={item.label}>
              {item.items ? (
                <React.Fragment>
                  {item.items.map(nested => (
                    <span key={nested.label} className="badge rounded-pill refinement-pill m-1">
                      <a
                        href={createURL(nested.value)}
                        onClick={event => {
                          event.preventDefault();
                          refine(nested.value);
                        }}
                      >
                        {replaceName(nested.label)}
                      </a>
                    </span>
                  ))}
                </React.Fragment>
              ) : (
                <span key={item.label} className="badge rounded-pill refinement-pill m-1">
                  <a
                    href={createURL(item.value)}
                    onClick={event => {
                      event.preventDefault();
                      refine(item.value);
                    }}
                  >
                    {replaceName(item.label)}
                  </a>
                </span>
              )}
            </span>
          ))}
        </div>
        <CustomClearRefinements className="align-self-end" translations={{
          reset: 'Filter aufheben',
        }}/>
      </div>
    </div>
  );};

CurrentRefinements.propTypes = {
  items: PropTypes.array.isRequired,
  refine: PropTypes.func.isRequired,
  createURL: PropTypes.func.isRequired,
  placeholder: PropTypes.bool,
};

export const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements);