import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {cartApi} from 'api/cartApi';
import {simmilarApi} from 'api/simmilarApi';
import { getRequestStatus } from 'helper/requestStatus';

export const cartSelectors = {
  cartStatus: state => state.cart.cartStatus,
  items: state => state.cart.items,
  hasItems: state => state.cart.items !== null && state.cart.items.length > 0,
  itemsIds: state => state.cart.items !== null ? state.cart.items.map(item => item.wineId) : [],
  deliveryDate: state => state.cart.deliveryDate,
  deliveryDates: state => state.cart.deliveryDates,
  comment: state => state.cart.comment,
  fetchCurrentCartRequest: state => getRequestStatus(
    state.cart.requests.fetchCurrentCart,
    state.cart.items !== null,
  ),
  addToCurrentCartRequest: state => getRequestStatus(
    state.cart.requests.addToCurrentCart,
    false,
  ),
  removeFromCurrentRequest: state => getRequestStatus(
    state.cart.requests.removeFromCurrentCart,
    false,
  ),
  submitCurrentCartRequest: state => getRequestStatus(
    state.cart.requests.submitCurrentCart,
    state.cart.cartStatus === 'sent',
  ),
  fetchDeliveryDatesRequest: state => getRequestStatus(
    state.cart.requests.fetchDeliveryDates,
    state.cart.deliveryDates !== null,
  ),
  simmilars: state => state.cart.simmilars,
  simmilarsRequest: state => getRequestStatus(
    state.cart.requests.getSimmilarWines,
    state.cart.simmilars !== null,
  ),
};

export const fetchCurrentCart = createAsyncThunk(
  'cart/fetchCurrentCart',
  async () => cartApi.currentCart()
);

export const addToCurrentCart = createAsyncThunk(
  'cart/addToCurrentCart',
  async (body) => cartApi.addToCurrentCart(body.wineId, body.quantity)
);

export const removeFromCurrentCart = createAsyncThunk(
  'cart/removeFromCurrentCart',
  async (body) => cartApi.removeFromCurrentCart(body.wineId)
);

export const submitCurrentCart = createAsyncThunk(
  'cart/submitCurrentCart',
  async (body, thunkAPI) => cartApi.submitCurrentCart(
    thunkAPI.getState().user.soldTo,
    thunkAPI.getState().user.shipTo,
    thunkAPI.getState().cart.deliveryDate.deliveryDate,
    thunkAPI.getState().cart.comment
  )
);

export const fetchDeliveryDates = createAsyncThunk(
  'cart/fetchDeliveryDates',
  async (body) => cartApi.fetchDeliveryDates(body.soldTo)
);

export const getSimmilarWines = createAsyncThunk(
  'cart/getSimmilarWines',
  async (body) => {
    if(body.authed) return simmilarApi.getSimmilarWinesAuthed(body.ids);
    else return simmilarApi.getSimmilarWinesPublic(body.ids);
  }
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: null,
    cartStatus: 'open', // open, confirm, sent
    deliveryDate: null,
    deliveryDates: null,
    comment: '',
    simmilars: null,
    requests: {
      fetchCurrentCart: {
        loading: false,
        error: null
      },
      addToCurrentCart: {
        loading: false,
        error: null
      },
      removeFromCurrentCart: {
        loading: false,
        error: null
      },
      submitCurrentCart: {
        loading: false,
        error: null
      },
      fetchDeliveryDates: {
        loading: false,
        error: null
      },
      getSimmilarWines: {
        loading: false,
        error: null,
      },
    }
  },
  reducers: {
    setCartStatus: (state, action)  => {
      state.cartStatus = action.payload.cartStatus;
    },
    setComment: (state, action)  => {
      state.comment = action.payload.comment;
    },
    setDeliveryDate: (state, action) => {
      state.deliveryDate = action.payload.deliveryDate;
    },
    resetDeliveryDates: (state) => {
      state.deliveryDate = null;
      state.deliveryDates = null;
    },
    resetSimmilars: (state) => {
      state.simmilars = null;
    },
    reset: (state) => {
      state.simmilars = null;
      state.items = null;
      state.cartStatus = 'open';
      state.deliveryDate = null;
      state.deliveryDates = null;
      state.comment = '';
    },
  },
  extraReducers: {
    [fetchCurrentCart.pending]: (state) => {
      state.requests.fetchCurrentCart.loading = true;
      state.requests.fetchCurrentCart.error = null;
    },
    [fetchCurrentCart.fulfilled]: (state, action) => {
      state.requests.fetchCurrentCart.loading = false;
      state.items = action.payload.orderItems;
    },
    [fetchCurrentCart.rejected]: (state, action) => {
      state.requests.fetchCurrentCart.loading = false;
      state.requests.fetchCurrentCart.error = action.error.message;
    },
    [addToCurrentCart.pending]: (state) => {
      state.requests.addToCurrentCart.loading = true;
      state.requests.addToCurrentCart.error = null;
    },
    [addToCurrentCart.fulfilled]: (state, action) => {
      state.requests.addToCurrentCart.loading = false;
      state.items = action.payload.orderItems;
    },
    [addToCurrentCart.rejected]: (state, action) => {
      state.requests.addToCurrentCart.loading = false;
      state.requests.addToCurrentCart.error = action.error.message;
    },
    [removeFromCurrentCart.pending]: (state) => {
      state.requests.removeFromCurrentCart.loading = true;
      state.requests.removeFromCurrentCart.error = null;
    },
    [removeFromCurrentCart.fulfilled]: (state, action) => {
      state.requests.removeFromCurrentCart.loading = false;
      state.items = action.payload.orderItems;
    },
    [removeFromCurrentCart.rejected]: (state, action) => {
      state.requests.removeFromCurrentCart.loading = false;
      state.requests.removeFromCurrentCart.error = action.error.message;
    },
    [submitCurrentCart.pending]: (state) => {
      state.requests.submitCurrentCart.loading = true;
    },
    [submitCurrentCart.fulfilled]: (state) => {
      state.requests.submitCurrentCart.loading = false;
      state.items = null;
      state.cartStatus = 'sent';
    },
    [submitCurrentCart.rejected]: (state, action) => {
      state.requests.submitCurrentCart.loading = false;
      state.requests.submitCurrentCart.error = action.error.message;
    },
    [fetchDeliveryDates.pending]: (state) => {
      state.requests.fetchDeliveryDates.loading = true;
      state.requests.fetchDeliveryDates.error = null;
    },
    [fetchDeliveryDates.fulfilled]: (state, action) => {
      state.requests.fetchDeliveryDates.loading = false;
      state.requests.fetchDeliveryDates.error = null;
      state.deliveryDates = action.payload;
    },
    [fetchDeliveryDates.rejected]: (state, action) => {
      state.requests.fetchDeliveryDates.loading = false;
      state.requests.fetchDeliveryDates.error = action.error.message;
    },
    [getSimmilarWines.pending]: (state) => {
      state.requests.getSimmilarWines.loading = true;
      state.requests.getSimmilarWines.error = null;
      state.simmilars = null;
    },
    [getSimmilarWines.fulfilled]: (state, action) => {
      state.requests.getSimmilarWines.loading = false;
      state.requests.getSimmilarWines.error = null;
      // TODO: Remove unique once the API works correctly...
      state.simmilars = action.payload.map(item => item && item.id ? item.id : null).filter((v, i, a) => a.indexOf(v) === i);
    },
    [getSimmilarWines.rejected]: (state, action) => {
      state.requests.getSimmilarWines.loading = false;
      state.requests.getSimmilarWines.error = action.error.message;
      state.simmilars = null;
    },
  }
});

const actions = {
  ...cartSlice.actions,
};
export {actions as cartActions};

export default cartSlice.reducer;