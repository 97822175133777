import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ESC, callOnKey } from 'helper/input';
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import {CSSTransition} from 'react-transition-group';
import './Modal.scss';

export function Modal({
  name = '',
  open,
  setOpen,
  position,
  size,
  shadow = true,
  closeable = true,
  title,
  body,
  foot,
  placeholderBody = false,
  modalBodyRef = null,
  disableBodyScrollOnOpen = true,
  parentBody = null,
}) {
  const wrapper = useRef();
  const modal = useRef();
  const modalHead = useRef();
  const modalBody = modalBodyRef !== null ? modalBodyRef : useRef();
  const modalFoot = useRef();
  const [openInteral, setOpenInternal] = useState(null);

  useEffect(() => callOnKey(ESC, () => open && closeable && setOpen(false)));

  useEffect(() => {
    if (openInteral != open) {
      setOpenInternal(open);
      if (disableBodyScrollOnOpen) {
        if (open) disableBodyScroll(modalBody.current);
        else enableBodyScroll(modalBody.current);
      }
      if(parentBody) {
        if (open) parentBody.current.style.overflowY = 'initial';
        else parentBody.current.style.overflowY = 'scroll';
      }
    } 
  }, [open]);

  const [scrollState, setScrollState] = useState({
    viewableWidth: null,
    scrollWidth: null,
    maximalScrollPosition: null,
    scrollPosition: null,
    percentageScrolled: null,
  });
  const handleScroll = (event) => {
    if (event) event.preventDefault();

    
    if (modalBody.current) {
      let scrollStateTmp = {};
      scrollStateTmp.viewableHeight = modalBody.current.offsetHeight;
      scrollStateTmp.scrollHeight = modalBody.current.scrollHeight;
      scrollStateTmp.maximalScrollPosition = scrollStateTmp.scrollHeight - scrollStateTmp.viewableHeight;
      scrollStateTmp.scrollPosition = modalBody.current.scrollTop;
      scrollStateTmp.percentageScrolled = scrollStateTmp.scrollPosition / scrollStateTmp.maximalScrollPosition;
      setScrollState(scrollStateTmp);
    }
  };
  useEffect(() => {
    handleScroll();
  }, [open]);

  return (
    <div ref={wrapper} className={position + ' ' + size}>
      <CSSTransition
        in={open}
        timeout={250}
        classNames="modal-overlay"
      >
        <div
          className="modal-overlay"
          onClick={() => closeable && setOpen(false)}>
        </div>
      </CSSTransition>

      <CSSTransition
        in={open}
        timeout={250}
        classNames="modal-wineapp"
      >
        <div ref={modal} className={`modal-wineapp ${shadow && 'shadow'} ${placeholderBody && 'placeholder-body'}`} name={name}>
          <div className="wrapper">
            <div className={`head ${scrollState.scrollPosition > 0 && 'head-shadow'}`} ref={modalHead}>
              {title}
            </div>

            <div className="body" ref={modalBody} onScroll={() => handleScroll()}>
              {body}
            </div>

            <div className={`foot ${(scrollState.percentageScrolled != 1 && scrollState.maximalScrollPosition > 0) && 'foot-shadow'}`} ref={modalFoot}>
              {foot}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

Modal.propTypes = {
  name: PropTypes.string,
  closeable: PropTypes.bool,
  usePortal: PropTypes.bool,
  placeholderBody: PropTypes.bool,
  modalBodyRef: PropTypes.any,
  open: PropTypes.any,
  setOpen: PropTypes.any,
  position: PropTypes.any,
  size: PropTypes.any,
  shadow: PropTypes.bool,
  title: PropTypes.any,
  body: PropTypes.any,
  foot: PropTypes.any,
  disableBodyScrollOnOpen: PropTypes.bool,
  parentBody: PropTypes.any,
};

export default Modal;