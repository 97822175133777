import React from 'react';
import {FormattedMessage} from 'react-intl';
import { ReactComponent as IconLogo } from 'components/icons/logo.svg';
import OutletSwitcherList from 'components/general/OutletSwitcherList';
import './OutletSwitcher.scss';

export function OutletSwitcher() {
  return (
    <div className="outlet-switcher">
      <IconLogo />
      <p>
        <FormattedMessage
          id="OutletSwitcher.Welcome"
        />

      </p>
      <OutletSwitcherList className="last-child-border" />
    </div>
  );
}

export default OutletSwitcher;