import React from 'react';
import {renderToString} from 'react-dom/server';
import {Provider} from 'react-redux';
import store from 'store';
import {IntlProvider} from 'react-intl';
import {PDF} from './PDF';
import messages from 'messages.json';


export default function GetPdfTemplate(product, language) {
  const choosenMessages = Object.keys(messages).reduce((acc, key) => {
    acc[key] = messages[key][language] || messages[key]['de'];
    return acc;
  }, {});

  const template = (product) => renderToString(
    <Provider store={store}>
      <IntlProvider messages={choosenMessages} locale={language} defaultLocale="de">
        <PDF product={product} language={language}/>
      </IntlProvider>
    </Provider>
  );

  return `
<!DOCTYPE html>
<html>

<head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap" rel="stylesheet">
  <script src="https://cdn.tailwindcss.com"></script>
  <script>
    tailwind.config = {
      theme: {
        extend: {
          colors: {
            // colors according to figma
            "primary": "#AF1430",
            "secondary": "#AC949E",
            "accent": "#E2AAB5",
            "accent2": "#ECC023",
            "focus": "#2D9CDB",
            "text": "#111111",
            "light-text": "#525252",
            "gray-1": "#A8A8A8",
            "gray-2": "#D8D8D8",
            "gray-3": "#EDEDED",
            "warm-gray": "#F7F4F2",
            "white": "#FFFFFF",
            "green": "#69A61C"
          },
          zIndex: {
            "popover": "20",
            "popover-arrow": "19",
            "modal": "50"
          },
          fontFamily: {
            'fira-sans': ['Fira Sans', 'sans-serif'],
            'barlow': ['Barlow', 'sans-serif']
          },
          dropShadow: {
            'wine-card': '0 0 17px rgba(0,0,0,0.2)',
            'dropdown-menu': '0 0 8px rgba(0,0,0,0.2)'
          },
          spacing: {
            "a4-width": "210mm",
            "a4-height": "296.75mm",
            "a4-page-margin-y": "20mm",
            "a4-page-margin-x": "20mm",
    
            "a4-half-width": "105mm",
            "a4-half-height": "296.75mm",
            "a4-half-page-margin-y": "20mm",
            "a4-half-page-margin-x": "5mm",
            
            "a5-width": "148mm",
            "a5-height": "209.75mm",
            "a5-page-margin-y": "10mm",
            "a5-page-margin-x": "10mm",
          },
          fontSize: {
            '2xs': '.625rem',
            '3xs': '.5rem'
          },
        },
      },
      plugins: [],
    }    
  </script>
  <style>
    body {
      margin: 0;
      font-family: 'Barlow', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  </style>
</head>

<body>
  ${template(product)}
</body>

</html>
`;
}