import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as IconLogo } from 'components/icons/logo.svg';
import './Navbar.scss';
import Cart from './Cart/Cart';
import Menu from './Menu';
import Search from './Search';
import {authSelectors} from '../../features/authSlice';
import {FormattedMessage} from 'react-intl';
import { Menu as UIMenu } from '@headlessui/react'


const Navbar = () => {
  const isLoggedIn = useSelector(authSelectors.loggedIn);

  return(
    <>
      <nav className="navbar-wineapp">
        <div className="top">
          <div className="left">
            <div className="me-3">
              <Menu />
            </div>
            <Link className="nav-link-custom" to="/">
              <IconLogo className="logo" />
            </Link>
          </div>
          <div className="middle">
            <Search />
          </div>
          <div className="right">
            {isLoggedIn ?
              <Cart />
              :
              <Link className="nav-link-custom" to="/login">
                <FormattedMessage
                  id="Navbar.Anmelden"
                  values={{ts: Date.now()}}
                />
              </Link>
            }
          </div>
        </div>
        <div className="bottom">
          <div className="left"></div>
          <div className="middle">
            <NavLink className="nav-link-custom" to="/" exact>
              <FormattedMessage
                id="Navbar.Start"
                values={{ts: Date.now()}}
              />
            </NavLink>

            <UIMenu className="position-relative" as="div">
              <UIMenu.Button className="nav-link-custom cursor-pointer" as="a">
                <FormattedMessage
                  id="Navbar.Sortiment"
                />
              </UIMenu.Button>
              <UIMenu.Items as="div" className="position-absolute top-100 left-0 bg-white d-flex flex-column shadow mt-2" style={{zIndex: 1000}}>
                <UIMenu.Item>
                  <NavLink className="nav-link-custom p-3" to="/products/all" exact reloadDocument>
                    <FormattedMessage
                      id="Navbar.allWines"
                    />
                  </NavLink>
                </UIMenu.Item>
                <UIMenu.Item>
                  <NavLink className="nav-link-custom p-3" to="/products/exclusive" exact reloadDocument>
                    <FormattedMessage
                      id="Navbar.UnserSortiment"
                    />
                  </NavLink>
                </UIMenu.Item>
                <UIMenu.Item>
                  <NavLink className="nav-link-custom p-3" to="/products/selection" exact reloadDocument>
                    <FormattedMessage
                      id="Navbar.Markenweine"
                    />
                  </NavLink>
                </UIMenu.Item>
              </UIMenu.Items>
            </UIMenu>

            {isLoggedIn && <NavLink className="nav-link-custom" to="/offers" exact>
              <FormattedMessage
                id="Navbar.Angebote"
                values={{ts: Date.now()}}
              />
            </NavLink>}
            <NavLink className="nav-link-custom" to="/collections" exact>
              <FormattedMessage id="Navbar.Kollektionen" />
            </NavLink>
            {(isLoggedIn) && <NavLink className="nav-link-custom" to="/favorites" exact>
              <FormattedMessage
                id="Navbar.MeineFavoriten"
              />
            </NavLink>}
            <NavLink className="nav-link-custom" to="/about" exact>
              <FormattedMessage
                id="Navbar.Bottle"
                values={{ts: Date.now()}}
              />
            </NavLink>
          </div>
          <div className="right"></div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;