import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {authSelectors} from '../../features/authSlice';
import Modal from 'components/general/Modal';
import {ReactComponent as IconArrowLeft} from '../icons/arrow-left.svg';
import {userActions, userSelectors} from '../../features/userSlice';
import ShipToSwitcher from './ShipToSwitcher';

export function ShipToSwitcherModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const soldTo = useSelector(userSelectors.soldTo);
  const shipTo = useSelector(userSelectors.shipTo);
  const shipToPrev = useSelector(userSelectors.shipToPrev);
  const customer = useSelector(authSelectors.customer);

  useEffect(() => {
    setOpen(isLoggedIn && shipTo == null && soldTo !== null && customer !== null);}, [isLoggedIn, shipTo, customer, soldTo]);

  return (
    <>
      <Modal
        name="shipto switcher"
        open={open}
        setOpen={setOpen}
        closeable={false}
        position="middle-side"
        title={shipToPrev && <div className="cursor-pointer" onClick={() => dispatch(userActions.setShipTo(shipToPrev))}>
          <IconArrowLeft className="me-3" />
          Zurück
        </div>}
        body={<ShipToSwitcher/>}
      />
    </>
  );
}

export default ShipToSwitcherModal;