import React, {useEffect} from 'react';
import {ReactComponent as IconContact} from 'components/icons/contact.svg';
import {getZenDesk} from 'libs/zenDesk';


/**
 * custom zen desk launcher as described <a href="https://developer.zendesk.com/documentation/classic-web-widget-sdks/web-widget/quickstart-tutorials/creating-a-launcher-for-the-web-widget/">here</a>
 * @constructor
 */
export function CustomZenDeskButton() {
  const zenDesk = getZenDesk();
  useEffect(() => {
    zenDesk.setShowDefaultBtn(false);
    return () => {
      zenDesk.setShowDefaultBtn(true);
    };
  }, []);

  return <a onClick={() => zenDesk.showChat()}>
    <IconContact/>
    <span>
      Kontakt
    </span>
  </a>;
}

