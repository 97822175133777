import {fsCockpitClient} from './fsCockpitClient';
import {wineAppPublicClient} from './wineAppClient';

function login(userName, password) {
  return fsCockpitClient.request({
    method: 'POST',
    url: '/token',
    responseType: 'json',
    data: {
      username: userName,
      password: password
    }
  }).then(response => {
    return checkWineAppAccess(response.data.access_token).then(() => response.data);
  });
}

function refresh(refreshToken) {
  return fsCockpitClient.request({
    method: 'GET',
    url: '/oauth/token',
    params: {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    }
  }).then(response => checkWineAppAccess(response.data.access_token).then(() => response.data));
}

function checkWineAppAccess(token) {
  return wineAppPublicClient.request({
    method: 'GET',
    url: '/ping',
    headers: {'Authorization': `Bearer ${token}`}
  });
}

function getUserInformation(token) {
  return fsCockpitClient.request({
    method: 'GET',
    url: '/user',
    headers: {'Authorization': `Bearer ${token}`}
  }).then(response => response.data);
}

export const authApi = {
  login,
  refresh,
  getUserInformation
};