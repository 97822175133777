import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import ProductCard from '../general/ProductCard.js';
import {WithAlgolia, useAlgoliaClient, useAlgoliaProductIndex} from '../algolia/WithAlgolia';
import Hero2 from './StartPageAssets/hero-2.png';
import './OfferPage.scss';
import {FormattedMessage} from 'react-intl';
import { userActions, userSelectors } from 'features/userSlice';

const FavoritesPage = () => {
  const dispatch = useDispatch();
  const algoliaClient = useAlgoliaClient();
  const productIndex = useAlgoliaProductIndex();
  const productIndexReady = productIndex && productIndex.appId;

  const soldTo = useSelector(userSelectors.soldTo);
  const favorites = useSelector(userSelectors.favorites);
  const favoritesRequest = useSelector(userSelectors.favoritesRequest);
  useEffect(() => {
    if (soldTo != null && favoritesRequest.status === 'idle') {
      dispatch(userActions.fetchFavorites({soldTo: soldTo}));
    }
  }, [soldTo, favoritesRequest.status]);

  const [favoritesProducts, setFavoritesProducts] = useState(null);
  const favoritesProductsNeedsReload = favorites !== (favoritesProducts ? favoritesProducts.map(item => item ? parseInt(item.objectID) : null) : null);
  useEffect(() => {
    if (productIndexReady && favoritesProductsNeedsReload && favorites) {
      productIndex.getObjects(favorites).then((result) => {
        setFavoritesProducts(result.results);
      });
    } else if (favorites === null || favorites.length == 0) {
      setFavoritesProducts([]);
    }
  }, [productIndexReady, favoritesRequest.status]);
  useEffect(() => {
    setFavoritesProducts([]);
  }, [productIndex]);

  return (
    <WithAlgolia>
      {algoliaClient && productIndexReady ?
        <div className="container-fluid px-md-3">
          <div className="row justify-content-center">
            <div className="col-12 col-md-9">
              <div className="offer-page-hero mb-4" style={{backgroundImage: `url(${Hero2})`}}>
                <h1>
                  <FormattedMessage
                    id="FavoritesPage.Favorites"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br/>}}
                  />

                </h1>
              </div>
              <div className="row px-3 px-md-0">
                {favoritesProducts && favoritesProducts.length > 0 ?
                  favoritesProducts.map((product) => (product && product.objectID) && (
                    <div key={product.objectID} className="col-xs-12 col-md-6 col-lg-6 col-xl-4 my-3">
                      <ProductCard product={product} shadow forceBorder />
                    </div>
                  ))
                  :
                  <FormattedMessage
                    id="FavoritesPage.Empty"
                  />
                }
              </div>
            </div>
          </div>
        </div>
        :
        <div className="container-fluid px-md-3 text-center">
          loading...
        </div>}
    </WithAlgolia>
  );
};

export default FavoritesPage;
