import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';


export class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-primary font-bold py-3 px-5">
          <FormattedMessage id="errorboundary.text"/>
        </div>
      );
    }

    return this.props.children;
  }

}

ErrorBoundary.propTypes = {
  children: PropTypes.any
};