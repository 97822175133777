import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {userActions, userSelectors} from 'features/userSlice';
import {authActions, authSelectors} from '../../features/authSlice';
import {reset} from 'features/searchSlice';
import Modal from 'components/general/Modal';
import {ReactComponent as IconMenu} from 'components/icons/menu.svg';
import {ReactComponent as IconLogo} from 'components/icons/logo.svg';
import {ReactComponent as IconClose} from 'components/icons/close.svg';
import { ReactComponent as IconSmallArrowLeft } from 'components/icons/small-arrow-left.svg';
import './Menu.scss';
import {FormattedMessage} from 'react-intl';
import { recommendationActions } from 'features/recommendationSlice';
import {choseCustomerActions} from '../../features/choseCustomerSlice';

export function Menu() {
  const modalBody = useRef();
  const [open, setOpen] = useState(false);
  const language = useSelector(userSelectors.language);

  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const isSales = useSelector(authSelectors.isSalesUser);
  const token = useSelector(authSelectors.accessToken);
  const soldToOutlet = useSelector(userSelectors.soldToOutlet);
  const shipToAddress = useSelector(userSelectors.shipToAddress);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authActions.logout());
    dispatch(userActions.logout());
    dispatch(choseCustomerActions.reset());
    dispatch(reset());
    dispatch(recommendationActions.resetPlz());
  };

  return (
    <>
      <span className="cursor-pointer" onClick={() => setOpen(true)}>
        <IconMenu/>
      </span>

      <Modal
        name="menu"
        open={open}
        setOpen={setOpen}
        position="left-side"
        size="small"
        title={<>
          <IconClose className="cursor-pointer icon me-4" onClick={() => setOpen(false)}/>
          <IconLogo/>
        </>}
        modalBodyRef={modalBody}
        closable={true}
        body={
          <div className="list">
            <div className="item d-flex justify-content-start align-center">
              {{
                'de': <>
                  <b className="me-2">DE</b>
                  <span className="cursor-pointer me-2" onClick={() => dispatch(userActions.setLanguage({language: 'fr'}))}>
                    FR
                  </span>
                  <span className="cursor-pointer me-2" onClick={() => dispatch(userActions.setLanguage({language: 'it'}))}>
                    IT
                  </span>
                </>,
                'fr': <>
                  <span className="cursor-pointer me-2" onClick={() => dispatch(userActions.setLanguage({language: 'de'}))}>
                    DE
                  </span>
                  <b className="me-2">FR</b>
                  <span className="cursor-pointer me-2" onClick={() => dispatch(userActions.setLanguage({language: 'it'}))}>
                    IT
                  </span>
                </>,
                'it': <>
                  <span className="cursor-pointer me-2" onClick={() => dispatch(userActions.setLanguage({language: 'de'}))}>
                    DE
                  </span>
                  <span className="cursor-pointer me-2" onClick={() => dispatch(userActions.setLanguage({language: 'fr'}))}>
                    FR
                  </span>
                  <b className="me-2">IT</b>
                </>,
              }[language] ||
                <>
                  <span className="cursor-pointer me-2" onClick={() => dispatch(userActions.setLanguage({language: 'de'}))}>
                    DE
                  </span>
                  <span className="cursor-pointer me-2" onClick={() => dispatch(userActions.setLanguage({language: 'fr'}))}>
                    FR
                  </span>
                  <span className="cursor-pointer me-2" onClick={() => dispatch(userActions.setLanguage({language: 'it'}))}>
                    IT
                  </span>
                </>}
            </div>

            {isLoggedIn ?
              <>
                <div className="item cursor-pointer" onClick={() => {
                  setOpen(false);
                  dispatch(userActions.setSoldTo(null));
                  dispatch(userActions.setShipTo(null));
                  //dispatch(authActions.setCustomer({customer: null}));
                }}>
                  <small>
                    { isSales ? <div>
                      <FormattedMessage
                        id="Menu.AngemeldetSalesManager"
                        values={{
                          ts: Date.now(),
                          breakingLine: <br/>}}/>
                      <b>
                        {soldToOutlet.name}, <br />
                        {soldToOutlet.street}, {soldToOutlet.zip} {soldToOutlet.city}
                      </b>
                    </div> :
                      soldToOutlet.name !== null ?
                        <>
                          <FormattedMessage
                            id="Menu.AngemeldetFür"
                            values={{
                              ts: Date.now(),
                              breakingLine: <br/>}}
                          />
                          <b>
                            {soldToOutlet.name}, <br />
                            {soldToOutlet.street}, {soldToOutlet.zip} {soldToOutlet.city}
                          </b>
                        </>
                        :
                        <i>
                          <FormattedMessage
                            id="Menu.LieferadresseÄndern"
                          />

                        </i>
                    }
                  </small>
                  <IconSmallArrowLeft className="arrow" />
                </div>

                <div className="item cursor-pointer" onClick={() => {
                  setOpen(false);
                  dispatch(userActions.setShipTo(null));
                }}>
                  <small>
                    { soldToOutlet.name !== null ?
                      <>
                        <FormattedMessage
                          id="Menu.Lieferadresse"
                          values={{
                            ts: Date.now(),
                            breakingLine: <br/>}}
                        />
                        <b>
                          {shipToAddress.name}, <br />
                          {shipToAddress.street}, {shipToAddress.zip} {shipToAddress.city}
                        </b>
                      </>
                      :
                      <i>
                        <FormattedMessage
                          id="Menu.LieferadresseÄndern"
                        />

                      </i>
                    }
                  </small>
                  <IconSmallArrowLeft className="arrow" />
                </div>

                <div className="item cursor-pointer" onClick={() => {
                  setOpen(false);
                  handleLogout();
                }}>
                  <span>
                    <b>
                      <FormattedMessage
                        id="Menu.Abmelden"
                        values={{
                          ts: Date.now(),
                          breakingLine: <br/>}}
                      />
                    </b>
                  </span>
                </div>
              </>
              :
              <div className="item" onClick={() => setOpen(false)}>
                <Link to="/login">
                  <b>
                    <FormattedMessage
                      id="Menu.Anmelden"
                      values={{ts: Date.now()}}
                    />
                  </b>
                </Link>
              </div>
            }
            <div className="item title">
              <FormattedMessage
                id="Menu.Weinsortiment"
                values={{ts: Date.now()}}
              />
            </div>
            <div className="item" onClick={() => setOpen(false)}>
              <Link to="/offers">
                <span>
                  <FormattedMessage
                    id="Menu.Weinsortiment.AngebotePromo"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <a href="/?toggle.flags=true#/products/all">
                <span>
                  <FormattedMessage
                    id="Menu.Weinsortiment.Bestseller"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </a>
            </div>
            <div className="item">
              <Link to="/collections" onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.Weinsortiment.Kollektionen"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <Link to="/products/exclusive" onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.Weinsortiment.UnserSortiment"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <Link to="/products/selection" onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.Weinsortiment.Markenweine"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            {isLoggedIn ?
              <div className="item">
                <Link to="/products/exclusive/1" replace onClick={() => setOpen(false)}>
                  <span>
                    <FormattedMessage
                      id="Menu.Weinsortiment.PersEmpf"
                      values={{ts: Date.now()}}
                    />
                  </span>
                  <IconSmallArrowLeft className="arrow" />
                </Link>
              </div>
              :
              <></>
            }
            <div className="item title">
              <FormattedMessage
                id="Menu.WeinKategorie"
                values={{ts: Date.now()}}
              />
            </div>
            <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'country',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.Land"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'region',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.Region"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'category',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.Weintyp"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'grapes',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.Rebsorten"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'suitableFood',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.PasstZu"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'bioVegan',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.Vegan"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'taste',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.Schweregrad"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'sweetness',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.Süssegrad"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            {isLoggedIn && <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'price',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.Preis"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>}
            <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'alcoholContent',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.Alkoholgehalt"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'closure',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.Verschluss"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item">
              <Link to={{
                pathname: '/products/all',
                state: {
                  filter: 'bottleSize',
                }
              }}
              replace
              onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.Flaschengrösse"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
            <div className="item title">
              <FormattedMessage
                id="Menu.Service"
                values={{ts: Date.now()}}
              />
            </div>
            <div className="item">
              {/* WARNING: In the sales Account there is never a customerNumber and therefore this link does not work propperly,
                           but Peer says this is not a problem... 
                           I still think it should be more predictable... for example: here soldToOutlet?.id (which is always set and true)
                           And in the function (soldTo AND !isSales)
               */}
              <a href={getWineMenuLink(isLoggedIn, token, soldToOutlet?.customerNumber)}>
                <span>
                  <FormattedMessage
                    id="Menu.Service.Weinkarte"
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </a>
            </div>
            <div className="item title">
              <FormattedMessage
                id="Menu.WeinKategorie.ÜberBottle"
                values={{ts: Date.now()}}
              />
            </div>
            <div className="item">
              <Link to="/about" onClick={() => setOpen(false)}>
                <span>
                  <FormattedMessage
                    id="Menu.WeinKategorie.ÜberBottle"
                    values={{ts: Date.now()}}
                  />
                </span>
                <IconSmallArrowLeft className="arrow" />
              </Link>
            </div>
          </div>
        }
      />
    </>
  );
}

function getWineMenuLink(isLoggedIn, token, soldTo) {
  let result = 'https://wine-menu.fs-apps.ch/';
  if (isLoggedIn && token != null) {
    result += `login/?token=${token}`;
    if (soldTo != null) {
      result += `&customer=${soldTo}`;
    }
  }

  return result;
}

export default Menu;