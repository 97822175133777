import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {userApi} from 'api/userApi';
import { getRequestStatus } from 'helper/requestStatus';

const LOCAL_STORAGE_OUTLET_KEY = 'outlet';
const LOCAL_STORAGE_SHIPTO_KEY = 'shipto';
const LOCAL_STORAGE_LANGUAGE_KEY = 'language';

const fetchOutlets = createAsyncThunk(
  'fetchOutlets',
  async () => userApi.outlets()
);

const fetchShipTos = createAsyncThunk(
  'fetchShipTos',
  async (body) => userApi.getCustomerShipTos(body.soldTo)
);

const fetchFavorites = createAsyncThunk(
  'fetchFavorites',
  async (body) => userApi.fetchFavorites(body.soldTo)
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    ...(getsoldToFromLocalStorage()),
    ...(getShipToFromLocalStorage()),
    ...(getLanguageFromLocalStorage()),
    soldToPrev: null,
    shipToPrev: null,
    user: {
      username: null
    },
    outlets: null,
    shipToList: null,
    triedRefresh: false,
    favorites: null,
    requests: {
      fetchOutlets: {
        loading: false,
        error: null,
      },
      fetchShipTos: {
        loading: false,
        error: null
      },
      fetchFavorites: {
        loading: false,
        error: null,
      }
    },
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      state.soldTo = null;
      state.outlets = null;
      state.shipTo = null;
      state.shipToList = null;
      state.favorites = null;
      state.requests.fetchOutlets.loading = false;
      state.requests.fetchOutlets.error = null;
      state.requests.fetchShipTos.loading = false;
      state.requests.fetchShipTos.error = null;
      state.requests.fetchFavorites.loading = false;
      state.requests.fetchFavorites.error = null;
    },
    setSoldTo: (state, action) => {
      state.soldToPrev = state.soldTo;
      state.soldTo = action.payload;
      savesoldToToLocalStorage(state.soldTo);
    },
    setShipTo: (state, action) => {
      state.shipToPrev = state.shipTo;
      state.shipTo = action.payload;
      saveShipToToLocalStorage(state.shipTo);
    },
    setOutlets: (state, action) => {
      state.outlets = [action.payload];
    },
    setLanguage: (state, action) => {
      state.language = action.payload.language;
      saveLanguageToLocalStorage(state.language);
    },
    logout: (state) => {
      state.username = null;
      state.soldTo = null;
      state.shipTo = null;
      state.shipToList = null;
      state.outlets = null;
      state.favorites = null;
      state.requests.fetchOutlets.loading = false;
      state.requests.fetchOutlets.error = null;
      state.requests.fetchFavorites.loading = false;
      state.requests.fetchFavorites.error = null;
      removeCustomerFromLocalStorage();
    },
    refreshFavorites: (state) => {
      state.favorites = null;
      state.requests.fetchFavorites.loading = false;
      state.requests.fetchFavorites.error = null;
    },
  },
  extraReducers: {
    [fetchOutlets.pending]: (state) => {
      state.requests.fetchOutlets.loading = true;
      state.requests.fetchOutlets.error = null;
    },
    [fetchOutlets.fulfilled]: (state, action) => {
      state.requests.fetchOutlets.loading = false;
      state.requests.fetchOutlets.error = null;
      state.outlets = action.payload.filter(item => item.smartOrderActive === true && item.priceListType !== 'WHOLESALER');
      if (state.outlets.length === 1) {
        state.soldTo = state.outlets[0].id;
        savesoldToToLocalStorage(state.soldTo);
      }
    },
    [fetchOutlets.rejected]: (state, action) => {
      state.requests.fetchOutlets.loading = false;
      state.requests.fetchOutlets.error = action.error.message;
    },
    [fetchShipTos.pending]: (state) => {
      state.requests.fetchShipTos.loading = true;
      state.requests.fetchShipTos.error = null;
    },
    [fetchShipTos.fulfilled]: (state, action) => {
      state.requests.fetchShipTos.loading = false;
      state.requests.fetchShipTos.error = null;
      state.shipToList = action.payload;
      if (state.shipToList.length === 1) {
        state.shipTo = state.shipToList[0].id;
        saveShipToToLocalStorage(state.shipTo);
      }
    },
    [fetchShipTos.rejected]: (state, action) => {
      state.requests.fetchShipTos.loading = false;
      state.requests.fetchShipTos.error = action.error.message;
    },
    [fetchFavorites.pending]: (state) => {
      state.requests.fetchFavorites.loading = true;
      state.requests.fetchFavorites.error = null;
    },
    [fetchFavorites.fulfilled]: (state, action) => {
      state.requests.fetchFavorites.loading = false;
      state.requests.fetchFavorites.error = null;
      state.favorites = action.payload.map(x => x.id);
    },
    [fetchFavorites.rejected]: (state, action) => {
      state.requests.fetchFavorites.loading = false;
      state.requests.fetchFavorites.error = action.error.message;
    },
  },
});

function getsoldToFromLocalStorage() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_OUTLET_KEY))
    ?? {
      soldTo: null,
    };
}

function getShipToFromLocalStorage() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_SHIPTO_KEY))
      ?? {
        shipTo: null,
      };
}

function savesoldToToLocalStorage(id) {
  localStorage.setItem(LOCAL_STORAGE_OUTLET_KEY, JSON.stringify({
    soldTo: id,
  }));
}


function saveShipToToLocalStorage(id) {
  localStorage.setItem(LOCAL_STORAGE_SHIPTO_KEY, JSON.stringify({
    shipTo: id,
  }));
}

function removeCustomerFromLocalStorage(){
  localStorage.removeItem(LOCAL_STORAGE_OUTLET_KEY);
  localStorage.removeItem(LOCAL_STORAGE_SHIPTO_KEY);
}

function getLanguageFromLocalStorage() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY))
    ?? {
      language: 'de',
    };
}

function saveLanguageToLocalStorage(language) {
  localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, JSON.stringify({
    language: language,
  }));
}

export const userSelectors = {
  outlets: state => state.user.outlets,
  outletsRequest: state => getRequestStatus(
    state.user.requests.fetchOutlets,
    state.user.outlets !== null,
  ),
  soldTo: state => state.user.soldTo,
  soldToPrev: state => state.user.soldToPrev,
  shipTo: state => state.user.shipTo,
  shipToAddress: state => {
    if (state.user.shipToList !== null && state.user.shipTo !== null) {
      const tmp = state.user.shipToList.filter(item => parseInt(item.id) == parseInt(state.user.shipTo))[0];
      return tmp ? tmp : {name:null};
    }
    return {name:null};
  },
  shipToPrev: state => state.user.shipToPrev,
  shipToList: state => state.user.shipToList,
  shipToListRequest: state => getRequestStatus(
    state.user.requests.fetchShipTos,
    state.user.shipToList !== null,
  ),
  soldToOutlet: state => {
    if (state.user.outlets !== null && state.user.soldTo !== null) {
      const tmp = state.user.outlets.filter(item => parseInt(item.id) == parseInt(state.user.soldTo))[0];
      return tmp ? tmp : {name:null};
    }
    return {name:null};
  },
  favorites: state => state.user.favorites,
  favoritesRequest: state => getRequestStatus(
    state.user.requests.fetchFavorites,
    state.user.favorites !== null,
  ),
  language: state => state.user.language,
};

const actions = {
  ...userSlice.actions,
  fetchOutlets,
  fetchFavorites,
  fetchShipTos,
};
export {actions as userActions};

export default userSlice.reducer;