// import {wineAppAuthedClient as fakeClient} from './fakeApiClient';
import {wineAppAuthedClient} from './wineAppClient';
// TODO: CORS error? maybe on the test system it works?
import {authedFsCockpitClient} from './fsCockpitClient';


function outlets() {
  // return fakeClient.request({
  //   method: 'GET',
  //   url: '/outlets.json',
  //   responseType: 'json'
  // }).then(response => response.data);
  return authedFsCockpitClient.request({
    method: 'GET',
    url: '/customer/list',
    responseType: 'json'
  }).then(response => response.data);
}

function getCustomerAddress(soldTo) {
  return wineAppAuthedClient.request({
    method: 'GET',
    url: `/customer/address/${soldTo}/`,
    responseType: 'json'
  }).then(response => response.data);
}

function getCustomerShipTos(soldTo) {
  return wineAppAuthedClient.request({
    method: 'GET',
    url: `/customer/shipTo/${soldTo}/`,
    responseType: 'json'
  }).then(response => response.data);
}

function fetchFavorites(soldTo) {
  return wineAppAuthedClient.request({
    method: 'GET',
    url: `/favorite/${soldTo}/`,
    responseType: 'json'
  }).then(response => response.data);
}

function addFavorites(soldTo, shipTo, matNumber) {
  return authedFsCockpitClient.request({
    method: 'POST',
    url: `/smartorder/shipTo/${soldTo}/favorite/`,
    data: {
      soldToNumber: soldTo,
      shipToNumber: shipTo,
      matNumber: matNumber
    },
    responseType: 'json'
  }).then(response => response.data);
}

function removeFavorites(soldTo, shipTo, matNumber) {
  return authedFsCockpitClient.request({
    method: 'Delete',
    url: `/smartorder/soldTo/${soldTo}/shipTo/${shipTo}/favorite/${matNumber}`,
    responseType: 'json'
  }).then(response => response.data);
}

export const userApi = {
  outlets,
  fetchFavorites,
  getCustomerAddress,
  getCustomerShipTos,
  addFavorites,
  removeFavorites,
};