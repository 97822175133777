import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, userSelectors } from 'features/userSlice';
import LoadingBottle from 'components/general/LoadingBottle';
import {FormattedMessage} from 'react-intl';
import { cartActions } from 'features/cartSlice';
import {authActions, authSelectors} from 'features/authSlice';
import { recommendationActions } from 'features/recommendationSlice';
import {reset} from 'features/searchSlice';
import { ReactComponent as IconSmallArrowLeft } from 'components/icons/small-arrow-left.svg';
import './ShipToSwitcherList.scss';

export function ShipToSwitcherList({
  className,
  onChange = null,
}) {
  const dispatch = useDispatch();

  const soldTo = useSelector(userSelectors.soldTo);
  const customer = useSelector(authSelectors.customer);
  const shipTos = useSelector(userSelectors.shipToList);
  const shipTosRequest = useSelector(userSelectors.shipToListRequest);

  useEffect(() => {
    if (soldTo !== null && customer !== null && shipTosRequest.status === 'idle' ) {
      dispatch(userActions.fetchShipTos({soldTo: soldTo}));
    }
  }, [soldTo, customer]);

  const handleSetShipTo = (shipTo) => {
    onChange && onChange();
    dispatch(userActions.setShipTo(shipTo));
    // dispatch(userActions.refresh());
    dispatch(cartActions.resetDeliveryDates());
    dispatch(userActions.refreshFavorites());
  };

  const handleLogout = () => {
    dispatch(authActions.logout());
    dispatch(userActions.logout());
    dispatch(reset());
    dispatch(recommendationActions.resetPlz());
  };

  return (
    <div className={className}>
      {{
        'idle':
                    <div className="shipTo-list-item loading">
                      <LoadingBottle />
                      <FormattedMessage
                        id="ShipToSwitcher.Loading"
                      />
                    </div>,
        'loading':
                    <div className="shipTo-list-item loading">
                      <LoadingBottle />
                      <FormattedMessage
                        id="ShipToSwitcher.Loading"
                      />
                    </div>,
        'success':
                    shipTos?.length > 0 ? shipTos?.map(shipTo => (
                      <div key={shipTo.id} className="shipTo-list-item cursor-pointer" onClick={() => handleSetShipTo(shipTo.id)}>
                        <b>
                          {shipTo.name},<br />
                          {shipTo.street}, {shipTo.postalCode} {shipTo.city}
                        </b>
                        <IconSmallArrowLeft />
                      </div>
                    )) : <div className="shipTo-list-item text-center">
                      <div className="mb-4">
                            Der Webshop ist nicht für sie freigegeben. Sie können die Weine jedoch trotzdem ansehen, wenn sie sich ausloggen.
                      </div>

                      <button className="btn btn-primary" onClick={() => handleLogout()}>
                            Abmelden
                      </button>
                    </div>,
        'error':
                    <div className="shipTo" onClick={() => dispatch(userActions.fetchShipTos({soldTo: soldTo}))}>
                      <FormattedMessage
                        id="ShipToSwitcher.Error"
                        values={{
                          msg: shipTosRequest.msg
                        }}
                      />
                    </div>,
      }[shipTosRequest.status]}
    </div>
  );
}

ShipToSwitcherList.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default ShipToSwitcherList;