import React from 'react';
import Hero from './StartPageAssets/header-d.png';
// import Cert from './AboutPageAssets/cert.png';
import Glasses from './AboutPageAssets/glasses.png';
import Bottle from './AboutPageAssets/bottle.png';

import './AboutPage.scss';

import {FormattedMessage} from 'react-intl';

const AboutPage = () => {
  return(
    <div className="container-fluid about-page">
      <div className="row justify-content-center">
        <div className="col-md-9 mb-5">
          <div className="hero" style={{backgroundImage: `url(${Hero})`}}>
            <h1>
              <FormattedMessage
                id="AboutPage.title"
                values={{ts: Date.now()}}
              />
            </h1>
          </div>
        </div>


        <div className="col-md-9 section-wrapper">
          <div className="row">
            <div className="col-6 d-none d-md-block">
              <div className="section glasses">
                <img src={Glasses} />
              </div>

              <div className="section bottle">
                <img src={Bottle} />
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="section one">
                <h2 className="fw-bold">
                  <FormattedMessage
                    id="AboutPage.about.title"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="AboutPage.about.text"
                    values={{ts: Date.now()}}
                  />
                </p>
              </div>

              <div className="section bottle d-block d-md-none">
                <img src={Glasses} />
              </div>

              <div className="section two">
                <h3>
                  <FormattedMessage
                    id="AboutPage.exclusive.title"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br />}}
                  />
                </h3>
                <p className="fw-bold">
                  <FormattedMessage
                    id="AboutPage.exclusive.subtitle"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br />}}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="AboutPage.exclusive.text"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br />}}
                  />
                </p>
              </div>

              <div className="section two">
                <h3>
                  <FormattedMessage
                    id="AboutPage.select.title"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br />}}
                  />
                </h3>
                <p className="fw-bold">
                  <FormattedMessage
                    id="AboutPage.select.subtitle"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br />}}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="AboutPage.select.text"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br />}}
                  />
                </p>
              </div>

              <div className="section bottle d-block d-md-none">
                <img src={Bottle} />
              </div>

              <div className="section three">
                <h3>
                  <FormattedMessage
                    id="AboutPage.certified.title"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br />}}
                  />                
                </h3>
                <p className="fw-bold">
                  <FormattedMessage
                    id="AboutPage.certified.subtitle"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br />}}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="AboutPage.certified.text"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br />}}
                  />                 
                </p>
              </div>

              {/* <div className="section cert">
                <div className="lines">
                  <p className="fw-bold">
                    <FormattedMessage
                      id="AboutPage.checked.title"
                      values={{
                        ts: Date.now(),
                        breakingLine: <br />}}
                    />
                  </p>
                  <p>
                    <img src={Cert} className="cert" />
                    <FormattedMessage
                      id="AboutPage.checked.text"
                      values={{
                        ts: Date.now(),
                        breakingLine: <br />}}
                    />
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
