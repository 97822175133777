import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  recommendationSelectors,
  recommendationActions,
} from 'features/recommendationSlice';
import {ReactComponent as IconClose} from 'components/icons/close.svg';
import {ReactComponent as IconPin} from 'components/icons/pin.svg';
import {ReactComponent as IconSmallArrowLeft} from 'components/icons/small-arrow-left.svg';
import {CustomRangeSlider} from '../algolia/CustomRangeSlider';
import Modal from './Modal';
import './Recommendation.scss';
import LoadingBottle from './LoadingBottle';
import {userSelectors} from 'features/userSlice';

import {FormattedMessage} from 'react-intl';

export function Recommendation({
  startRecommendation = false,
  // exclusive = false,
}) {
  // state
  const recommendationActive = useSelector(recommendationSelectors.recommendationActive);
  const plz = useSelector(recommendationSelectors.plz);
  const plzInfo = useSelector(recommendationSelectors.plzInfo);
  const soldToOutlet = useSelector(userSelectors.soldToOutlet);


  const searchResults = useSelector(recommendationSelectors.searchResults);
  const searchResultsRequest = useSelector(recommendationSelectors.searchResultsRequest);
  const dispatch = useDispatch();

  // open/close stuff
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [errorPlz, setErrorPlz] = useState('');

  useEffect(() => {
    if(startRecommendation) setOpen(open);
  });

  const handleClose = (reset = false) => {
    setOpen(false);
    if(reset) dispatch(recommendationActions.resetPlz());
    else dispatch(recommendationActions.setActive());
    history.push('/products/exclusive');
  };

  const handleInput = (query) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    if(searchQuery != '') {
      dispatch(recommendationActions.fetchSearchResults({
        query: searchQuery,
      }));
      dispatch(recommendationActions.resetPlz());
    } else {
      dispatch(recommendationActions.resetPlz());
      dispatch(recommendationActions.resetSearch());
    }
    setErrorPlz('');
  }, [searchQuery]);

  const handleReset = () => {
    setSearchQuery('');
    setErrorPlz('');
    dispatch(recommendationActions.resetPlz());
  };

  useEffect(() => {
    if (searchResultsRequest.status === 'error') {
      setErrorPlz('Gib eine gültige Postleitzahl ein');
    }
  }, [searchResultsRequest.status]);

  useEffect(() => {
    setOpen(startRecommendation ? true : false);
  }, [startRecommendation]);

  useEffect(() => {
    if(soldToOutlet?.zip) setSearchQuery(soldToOutlet.zip);
  }, [soldToOutlet]);

  useEffect(() => {
    if (open && searchResultsRequest.status === 'success' && searchResults.length == 1) {
      dispatch(recommendationActions.setPlz({
        plz: searchResults[0].plz,
        plzInfo: searchResults[0],
      }));
    } else if(open && searchResultsRequest.status === 'success' && searchResults.length > 1) {
      dispatch(recommendationActions.resetPlz());
    }
  }, [open, searchResultsRequest.status]);

  return (
    <>
      <div className="recommendation p-2 mb-3">
        {!recommendationActive ?
          <div className="title d-flex" onClick={() => setOpen(true)}>
            <IconPin />&nbsp;
            <FormattedMessage
              id="Recommendation.PersSortEmpf"
              values={{
                ts: Date.now()
              }}
            />
            <IconSmallArrowLeft className="justify-self-end" />
          </div>
          :
          <>
            <div className="title">
              <IconPin />&nbsp;
              <FormattedMessage
                id="Recommendation.DeineEmpfehlungen"
                values={{ts: Date.now()}}
              />
              &nbsp;
              <span className="plz" onClick={() => handleReset()}>
                {plz}
                <span className="d-none d-md-block">&nbsp;{plzInfo.city}</span>
                <div className="close-circle">
                  <IconClose onClick={() => setOpen(false)} />
                </div>
              </span>
            </div>
            <div className="mt-3">
              <FormattedMessage
                id="Recommendation.Preisspanne"
                values={{ts: Date.now()}}
              />
              <CustomRangeSlider attribute="sale_price_per_bottle" title="Preis" unit="CHF" />
            </div>
          </>
        }
      </div>

      <Modal
        name="recommendation"
        open={open}
        setOpen={setOpen}
        closable={true}
        size="small"
        position="middle-side"
        usePortal={false}
        title={<>
          <span>
            <FormattedMessage
              id="Recommendation.PersEmpf"
              values={{
                ts: Date.now(),
                breakingLine: <br/>
              }}
            />

          </span>
          <IconClose className="icon ms-auto" onClick={() => handleClose(true)} />
        </>}
        body={
          <>
            <div className="modal-plz">
              <div className="d-flex align-items-center mb-3">
                <div className="icon-small">
                  <IconPin />
                </div>
                <div className="title-small">
                  <FormattedMessage
                    id="Recommendation.Pers<br>Empf"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br/>
                    }}
                  />
                </div>
              </div>
              <p>
                <FormattedMessage
                  id="Recommendation.EinfachStandort"
                  values={{
                    b(fragments) {
                      return <b>{fragments}</b>;
                    }
                  }}
                />
              </p>
              <input
                className="form-control rounded-pill"
                placeholder="PLZ oder Ort eingeben"
                value={searchQuery}
                onChange={(ev) => handleInput(ev.target.value)}
              />
              <div className="error">{errorPlz}</div>
              {plzInfo && <div className="text-muted">
                <FormattedMessage
                  id="Recommendation.Postleitzahl"
                />
                &nbsp;{plzInfo ? plzInfo.plz : ''} {plzInfo ? plzInfo.city : ''}
              </div>}
            </div>
            <div className="list-suggest">
              {/* <div className="list-item title">
                Dein Ort
              </div>
              <a className="list-item" onClick={() => handleInput(soldToOutlet.zip)}>
                <span>{soldToOutlet.zip} {soldToOutlet.city}</span>
                <IconSmallArrowLeft />
              </a> */}
              <div className="list-item title">
                Vorschläge
              </div>
              <>
                {{
                  'idle': <div className="list-item">
                    <i>Gib einen Ort ein um Vorschläge anzuzeigen</i>
                  </div>,
                  'loading': <div className="list-item justify-content-center">
                    <LoadingBottle color="#b1b1b1"/>
                  </div>,
                  'success': searchResults && searchResults.length > 0 && searchResults.map(item => <a key="item.plz" className="list-item" onClick={() => dispatch(recommendationActions.setPlz({
                    plz: item.plz,
                    plzInfo: item,
                  }))}>
                    <span>{item.plz} {item.city}</span>
                    <IconSmallArrowLeft />
                  </a>) || <div className="list-item">
                    <i>Keinen passenden Ort gefunden</i>
                  </div>,
                  'error': <div className="error">{searchResultsRequest.msg}</div>,
                }[searchResultsRequest.status]}
              </>
            </div>
          </>
        }
        foot={<>
          <button
            className="primary full"
            disabled={plz === null}
            onClick={() => handleClose()}>
            <FormattedMessage
              id="Recommendation.Empfehlungen"
              values={{
                city: plzInfo?.city ?? '...',
                b(fragments) {
                  return <b>{fragments}</b>;
                }
              }}
            />
          </button>
        </>}
      />
    </>
  );
}

Recommendation.propTypes = {
  startRecommendation: PropTypes.bool,
};

export default Recommendation;