import React from 'react';
import PropTypes from 'prop-types';
import { connectStats } from 'react-instantsearch-dom';
import {FormattedMessage} from 'react-intl';

const Stats = ({
  // processingTimeMS,
  nbHits,
  // nbSortedHits,
  // areHitsSorted
  listName,
}) => (
  <div>
    <FormattedMessage
      id="NumberOfHits"
      defaultMessage=" Weine gefunden"
      values={{
        count: nbHits.toLocaleString(),
        listName: listName,
      }}
    />
  </div>
);

Stats.propTypes = {
  processingTimeMS: PropTypes.number,
  nbHits: PropTypes.number,
  nbSortedHits: PropTypes.object,
  areHitsSorted: PropTypes.bool,
  listName: PropTypes.string,
};

export const NumberOfHits = connectStats(Stats);