import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addToCurrentCart, cartSelectors } from 'features/cartSlice';
import { ReactComponent as IconCart } from 'components/icons/cart.svg';
import { useAlert } from 'react-alert';
import './AddToCart.scss';
import LoadingBottle from '../LoadingBottle';

const AddToCart = ({
  productId,
  name = null,
  outOfStock = false,
  nextAvailabilityDate = null,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [msg, setMsg] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const addToCurrentCartRequest = useSelector(cartSelectors.addToCurrentCartRequest);
  const [loading, setLoading] = useState(false);

  const discountEndDateInstance = new Date(nextAvailabilityDate);

  const handleAddToCart = () => {
    setLoading(true);
    dispatch(addToCurrentCart({
      wineId: parseInt(productId),
      quantity: quantity,
    }));
    setMsg(name);
  };

  useEffect(() => {
    if(msg) {
      if (addToCurrentCartRequest.status === 'idle') {
        alert.info(`${quantity}x ${msg} wurde dem Warenkorb hinzugefügt...`);
        setMsg(null);
      }

      if (addToCurrentCartRequest.status === 'error') {
        alert.error(`${msg} konnte nicht zum Warenkorb hinzugefügt werden: ${addToCurrentCartRequest.msg}`);
        setMsg(null);
      }

      if (addToCurrentCartRequest.status !== 'loading') {
        setLoading(false);
      }
    }
  }, [addToCurrentCartRequest.status]);

  return(
    <div className="add-to-cart-module bottom-wrapper" onClick={(ev) => ev.preventDefault()}>
      {outOfStock ?
        <button className="btn btn-outline-secondary out-of-stock flex-grow-1 d-flex justify-content-center align-items-center" disabled>
          {nextAvailabilityDate ?
            <>
              wieder verfügbar ab {`${discountEndDateInstance.getDate()}.${discountEndDateInstance.getMonth()+1}.${discountEndDateInstance.getFullYear()}`}
            </>
            :
            <>
              Ausverkauft
            </>
          }
        </button>
        :
        <>
          <div className="me-3 d-flex justify-content-center align-items-center" role="group">
            <button className="btn decrement d-flex justify-content-center align-items-center"
              onClick={() => setQuantity(quantity > 1 ? quantity-1 : 1)}
              disabled={quantity <= 1}>
              -
            </button>
            <span className='d-flex justify-content-center text-dark fw-bold' style={{minWidth: '60px'}}>
              {quantity}
            </span>
            <button className="btn increment d-flex justify-content-center align-items-center"
              onClick={() => setQuantity(quantity+1)}>
              +
            </button>
          </div>
          <button className="btn btn-primary d-flex justify-content-center align-items-center"
            onClick={() => handleAddToCart()}
            disabled={loading}>
            {loading ?
              <LoadingBottle />
              :
              <IconCart />
            }
          </button>
        </>
      }
    </div>
  );
};

AddToCart.propTypes = {
  productId: PropTypes.string.isRequired,
  name: PropTypes.string,
  outOfStock: PropTypes.bool,
  nextAvailabilityDate: PropTypes.string,
};

export default AddToCart;