import {wineAppPublicClient} from './wineAppClient';
import {wineAppAuthedClient} from './wineAppClient';

function getSimmilarWinesPublic(ids) {
  return wineAppPublicClient.request({
    method: 'POST',
    url: '/wine/recommend/',
    data: {
      recommendationBase: ids,
    },
    responseType: 'json'
  }).then(response => response.data);
}

function getSimmilarWinesAuthed(ids) {
  return wineAppAuthedClient.request({
    method: 'POST',
    url: '/wine/recommend/',
    data: {
      recommendationBase: ids,
    },
    responseType: 'json'
  }).then(response => response.data);
}

export const simmilarApi = {
  getSimmilarWinesPublic,
  getSimmilarWinesAuthed,
};