import {configureStore} from '@reduxjs/toolkit';

import {algoliaCredentialsReducer} from 'features/algoliaCredentialsSlice';
import {authReducer} from 'features/authSlice';
import cartReducer from 'features/cartSlice';
import recommendationReducer from 'features/recommendationSlice';
import searchReducer from 'features/searchSlice';
import simmilarReducer from 'features/simmilarSlice';
import userReducer from 'features/userSlice';
import {choseCustomerReducer} from './features/choseCustomerSlice';

export default configureStore({
  reducer: {
    cart: cartReducer,
    auth: authReducer,
    algoliaCredentials: algoliaCredentialsReducer,
    choseCustomer: choseCustomerReducer,
    user: userReducer,
    search: searchReducer,
    simmilar: simmilarReducer,
    recommendation: recommendationReducer,
  }
});