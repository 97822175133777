import {wineAppAuthedClient} from './wineAppClient';

function favorites() {
  return wineAppAuthedClient.request({
    method: 'GET',
    url: '/favorite',
    responseType: 'json'
  }).then(response => response.data);
}

function addFavorite(id,soldTo) {
  return wineAppAuthedClient.request({
    method: 'POST',
    url: `/favorite/${soldTo}/`,
    responseType: 'json',
    data: {wineId: id}
  }).then(response => response.data);
}

function removeFavorite(id,soldTo) {
  return wineAppAuthedClient.request({
    method: 'DELETE',
    url: `/favorite/${soldTo}/`,
    params:{
      wine:id
    },
    responseType: 'json',
  }).then(response => response.data);
}

export const searchApi = {
  favorites,
  addFavorite,
  removeFavorite,
};