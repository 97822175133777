import React from 'react';
import PropTypes from 'prop-types';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searchSelectors, setQuery } from 'features/searchSlice';

const ClearRefinements = ({
  items,
  refine,
  translations = {
    reset: 'Filter aufheben',
  },
}) => {
  const dispatch = useDispatch();

  const query = useSelector(searchSelectors.query);
  const isDisabled = !items.length && query == '';

  const handleClick = () => {
    refine(items);
    // TODO: why does it only work when I reset the query after the refinements...
    setTimeout(() => {
      dispatch(setQuery({query: ''}));
    }, 500);
  };

  const style = {
    fontStyle: 'italic',
  };

  return (
    <button onClick={() => handleClick()} disabled={isDisabled} style={style}>
      {translations && translations.reset || 'Filter aufheben'}
    </button>
  );
};

ClearRefinements.propTypes = {
  items: PropTypes.array.isRequired,
  refine: PropTypes.func.isRequired,
  translations: PropTypes.object,
};

export const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);