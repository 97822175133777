import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors, userActions } from 'features/userSlice';

import { ReactComponent as IconPin } from 'components/icons/pin.svg';
import { ReactComponent as IconSmallArrowLeft } from 'components/icons/small-arrow-left.svg';
import { ReactComponent as IconOneCoin } from 'components/icons/one-coin.svg';
import { ReactComponent as IconThreeCoins } from 'components/icons/three-coins.svg';
import { ReactComponent as IconFiveCoins } from 'components/icons/five-coins.svg';
import { ReactComponent as IconSixCoins } from 'components/icons/six-coins.svg';
import {ReactComponent as IconGlobe} from 'components/icons/globe.svg';
import {ReactComponent as IconGrapes} from 'components/icons/grapes.svg';
import {ReactComponent as IconGlass} from 'components/icons/glass.svg';
import Hero from 'components/pages/StartPageAssets/start.png';
import HeaderA from 'components/pages/StartPageAssets/header-a.png';
import HeaderB from 'components/pages/StartPageAssets/header-b.png';
import HeaderC from 'components/pages/StartPageAssets/header-c.png';
import HeaderD from 'components/pages/StartPageAssets/header-d.png';
import HeaderE from 'components/pages/StartPageAssets/header-e.png';
import RedWineBg from './StartPageAssets/red.png';
import WhiteWineBg from './StartPageAssets/white.png';
import RoseWineBg from './StartPageAssets/rose.png';
import SparklingWineBg from './StartPageAssets/champagner.png';

import {WithAlgolia, useAlgoliaProductIndex} from 'components/algolia/WithAlgolia';
import HorizontalScrollWrapper from 'components/algolia/HorizontalScrollWrapper';
import {authSelectors} from '../../features/authSlice';

import {FormattedMessage} from 'react-intl';

import './StartPage.scss';
import Slider from 'components/general/Slider';

const StartPage = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const productIndex = useAlgoliaProductIndex();
  const productIndexReady = productIndex && productIndex.appId;

  const [promotionProducts, setPromotionProducts] = useState(null);
  useEffect(() => {
    if (productIndexReady && !promotionProducts) {
      productIndex.search('', {
        filters: 'isDiscount:true',
      }).then((result) => {
        setPromotionProducts(result.hits);
      });
    }
  }, [productIndex, isLoggedIn]);

  useEffect(() => {
    setPromotionProducts(null);
  }, [productIndex]);

  const soldTo = useSelector(userSelectors.soldTo);
  //shipTo
  const favorites = useSelector(userSelectors.favorites);
  const favoritesRequest = useSelector(userSelectors.favoritesRequest);
  useEffect(() => {
    if (soldTo != null && favoritesRequest.status === 'idle') {
      // fetch Favorites for shipTo
      dispatch(userActions.fetchFavorites({soldTo: soldTo}));
    }
  }, [soldTo, favoritesRequest.status]);

  const [favoritesProducts, setFavoritesProducts] = useState(null);
  const favoritesProductsNeedsReload = favorites !== (favoritesProducts ? favoritesProducts.map(item => item ? parseInt(item.objectID) : null) : null);
  useEffect(() => {
    if (productIndexReady && favoritesProductsNeedsReload && favorites) {
      productIndex.getObjects(favorites).then((result) => {
        setFavoritesProducts(result.results);
      });
    } else if (favorites === null || favorites.length == 0) {
      setFavoritesProducts([]);
    }
  }, [productIndexReady, favoritesRequest.status]);

  useEffect(() => {
    setFavoritesProducts([]);
  }, [productIndex]);

  return(
    <WithAlgolia>
      <div className="container-fluid start-page">
        <div className="row justify-content-center">
          <div className="col-md-9">
            <Slider
              slides={[
                {
                  imgUrl: Hero,
                  title: <FormattedMessage id="StartPage.Hero.1.Title" />,
                  subtitle: <FormattedMessage id="StartPage.Hero.1.Subtitle" />,
                },
                {
                  imgUrl: HeaderE,
                  href: '/#products/selection',
                  title: <FormattedMessage id="StartPage.Hero.Selection.Title" />,
                },
                {
                  imgUrl: HeaderC,
                  href: '/#offers',
                  title: <FormattedMessage id="StartPage.Hero.Offers.Title" />,
                },
                {
                  imgUrl: HeaderB,
                  href: '/?toggle.flags=true&page=1#/products/exclusive',
                  title: <FormattedMessage id="StartPage.Hero.Exclusive.Title" />,
                },
                {
                  imgUrl: HeaderA,
                  href: '/#collections',
                  title: <FormattedMessage id="StartPage.Hero.Collections.Title" />,
                },
                {
                  imgUrl: HeaderD,
                  href: '/#about',
                  title: <FormattedMessage id="StartPage.Hero.About.Title" />,
                },
              ]}
            />

            {isLoggedIn && <div className="section">
              <h2>
                <FormattedMessage id="StartPage.AktuelleAngebote" />
                <Link to="/offers">
                  <FormattedMessage
                    id="StartPage.MehrAnzeigen"
                  />
                </Link>
              </h2>
              <HorizontalScrollWrapper products={promotionProducts} placeholder="Keine Angebote gefunden" />
            </div>}

            <div className="best-rec-wrapper">
              <div className="section recommendation">
                <div className="d-flex align-items-center mb-2">
                  <div className="icon me-1">
                    <IconPin />
                  </div>
                  <h2>
                    <FormattedMessage
                      id="StartPage.PersEmpf"
                      values={{
                        ts: Date.now(),
                        breakingLine: <br />}}
                    />
                  </h2>
                </div>
                <p>
                  <FormattedMessage
                    id="StartPage.PersEmpf.StaEing"
                    values={{
                      // eslint-disable-next-line react/display-name
                      b: chunks => <b>{chunks}</b>,
                      breakingLine: <br />,
                    }}
                  />
                </p>
                {isLoggedIn ?
                  <Link to="/products/exclusive/1" className="rounded-pill">
                    <span>
                      <FormattedMessage
                        id="StartPage.PersEmpf.Input"
                        values={{
                          ts: Date.now(),
                          breakingLine: <br />}}
                      />
                    </span>
                    <span><IconSmallArrowLeft /></span>
                  </Link>
                  :
                  <p>
                    <FormattedMessage
                      id="StartPage.PersEmpf.NichtEingeloggt"
                    />
                  </p>
                }
              </div>

              <div className="section bestseller">
                <h2>
                  <FormattedMessage
                    id="StartPage.Weintyp"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br />}}
                  />
                </h2>
                <div className="row">
                  <a href="/?refinementList.category=Weisswein#/products/all" style={{backgroundImage: `url(${WhiteWineBg})`}}>
                    <FormattedMessage
                      id="StartPage.Weintyp.Weissweine"
                      values={{
                        ts: Date.now(),
                        breakingLine: <br />}}
                    />
                  </a>
                  <a href="/?refinementList.category=Rotwein#/products/all" style={{backgroundImage: `url(${RedWineBg})`}}>
                    <FormattedMessage
                      id="StartPage.Weintyp.Rotweine"
                      values={{
                        ts: Date.now(),
                        breakingLine: <br />}}
                    />
                  </a>
                  <a href="/?refinementList.category=Rosé#/products/all" style={{backgroundImage: `url(${RoseWineBg})`}}>
                    <FormattedMessage
                      id="StartPage.Weintyp.Rosé"
                      values={{
                        ts: Date.now(),
                        breakingLine: <br />}}
                    />
                  </a>
                  <a href="/?refinementList.category=Schaumwein#/products/all" style={{backgroundImage: `url(${SparklingWineBg})`}}>
                    <FormattedMessage
                      id="StartPage.Weintyp.Schaumweine"
                      values={{
                        ts: Date.now(),
                        breakingLine: <br />}}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="section">
              <a href="/#products/selection">
                <div style={{borderRadius: '15px', position: 'relative', overflow: 'hidden', height: '250px'}}>
                  <img src={HeaderE} style={{width: '100%'}} />
                  <h1 style={{
                    position: 'absolute',
                    bottom: '15px',
                    left: '15px',
                    color: 'white',
                  }}>
                    Entdecken Sie unsere<br />Markenweine
                  </h1>
                </div>
              </a>
            </div>

            {(isLoggedIn) ? <div className="section">
              <h2>
                <FormattedMessage
                  id="StartPage.MeineLetztenBestellungen"
                  values={{
                    ts: Date.now(),
                    breakingLine: <br />}}
                />
                <Link to="/favorites">
                  <FormattedMessage
                    id="StartPage.MehrAnzeigen"
                  />
                </Link>
              </h2>
              <HorizontalScrollWrapper
                products={favoritesProducts}
                placeholder={
                  <FormattedMessage
                    id="StartPage.KeineLetztenBestellungen"
                  />
                }
              />
            </div> : <></>}

            {isLoggedIn && <div className="section prices">
              <h2>
                <FormattedMessage
                  id="StartPage.NachPreisen"
                  values={{
                    ts: Date.now(),
                    breakingLine: <br/>}}
                />
              </h2>
              <div className="row prices">
                <a href="/?range.sale_price_per_bottle.max=10#/products/all">
                  <IconOneCoin />
                  <FormattedMessage
                    id="StartPage.NachPreisen.UnterCHF10"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br/>}}
                  />
                </a>
                <a href="/?range.sale_price_per_bottle.min=10&range.sale_price_per_bottle.max=15#/products/all">
                  <IconThreeCoins />
                  <FormattedMessage
                    id="StartPage.NachPreisen.CHF10-CHF15"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br/>}}
                  />
                </a>
                <a href="/?range.sale_price_per_bottle.min=15&range.sale_price_per_bottle.max=20#/products/all">
                  <IconFiveCoins />
                  <FormattedMessage
                    id="StartPage.NachPreisen.CHF15-CHF20"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br/>}}
                  />
                </a>
                <a href="/?range.sale_price_per_bottle.min=20#/products/all">
                  <IconSixCoins />
                  <FormattedMessage
                    id="StartPage.NachPreisen.UeberCHF20"
                    values={{
                      ts: Date.now(),
                      breakingLine: <br/>}}
                  />
                </a>
              </div>
            </div>}

            <div className="d-block d-md-none section categories">
              <h2>
                <FormattedMessage
                  id="StartPage.WeinNachKat"
                />
              </h2>
              <div className="list-categories">
                <Link to={{
                  pathname: '/products/all',
                  state: {
                    filter: 'country',
                  }
                }}
                replace
                className="list-categories-item">
                  <IconGlobe className="icon" />
                  <span className="me-auto">
                    <FormattedMessage
                      id="StartPage.Land"
                    />
                  </span>
                  <IconSmallArrowLeft className="arrow" />
                </Link>
                <Link to={{
                  pathname: '/products/all',
                  state: {
                    filter: 'region',
                  }
                }}
                replace
                className="list-categories-item">
                  <IconGlobe className="icon" />
                  <span className="me-auto">
                    Region
                  </span>
                  <IconSmallArrowLeft className="arrow" />
                </Link>
                <Link to={{
                  pathname: '/products/all',
                  state: {
                    filter: 'category',
                  }
                }}
                replace
                className="list-categories-item">
                  <IconGlass className="icon" />
                  <span className="me-auto">
                    Weintyp
                  </span>
                  <IconSmallArrowLeft className="arrow" />
                </Link>
                <Link to={{
                  pathname: '/products/all',
                  state: {
                    filter: 'grapes',
                  }
                }}
                replace
                className="list-categories-item">
                  <IconGrapes className="icon" />
                  <span className="me-auto">
                    Rebsorte
                  </span>
                  <IconSmallArrowLeft className="arrow" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WithAlgolia>
  );
};

export default StartPage;
