import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {
  Configure,
  InstantSearch,
  ToggleRefinement,
} from 'react-instantsearch-dom';

import {algoliaCredentialsSelectors} from '../../features/algoliaCredentialsSlice';
import ProductCard from '../general/ProductCard.js';
import {WithAlgolia, useAlgoliaClient, useAlgoliaProductIndex} from '../algolia/WithAlgolia';
import Hero2 from './StartPageAssets/header-a.png';
import './CollectionsPage.scss';
import { CustomHitsHSW } from 'components/algolia/CustomHitsHSW';
import { userSelectors } from 'features/userSlice';
import {FormattedMessage} from 'react-intl';

const CollectionsPage = () => {
  const algoliaClient = useAlgoliaClient();
  const productIndex = useAlgoliaProductIndex();
  const productIndexName = useSelector(algoliaCredentialsSelectors.productIndex);
  const productIndexReady = productIndex && productIndex.appId;

  const language = useSelector(userSelectors.language);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    if (productIndexReady) {
      if(language == 'de') {
        productIndex?.search('', {
          facets: ['collections'],
        }).then(({ facets }) => {
          setCollections(Object.keys(facets.collections).sort());
        });
      }
      if(language == 'fr') {
        productIndex?.search('', {
          facets: ['collectionsFr'],
        }).then(({ facets }) => {
          setCollections(Object.keys(facets.collectionsFr).sort());
        });
      }
      if(language == 'it') {
        productIndex?.search('', {
          facets: ['collectionsIt'],
        }).then(({ facets }) => {
          setCollections(Object.keys(facets.collectionsIt).sort());
        });
      }
    }
  }, [productIndex, language]);

  return (
    <WithAlgolia>
      {algoliaClient && productIndexName ?
        <div className="container-fluid px-md-3">
          <div className="row justify-content-center">
            <div className="col-12 col-md-9">
              <div className="collection-page-hero mb-4" style={{backgroundImage: `url(${Hero2})`}}>
                <h1><FormattedMessage id="Collections.Title" /></h1>
              </div>
              <>
                {collections.length > 0 && collections.map(item =>
                  <InstantSearch
                    key={item}
                    indexName={productIndexName}
                    searchClient={algoliaClient}
                  >
                    <ToggleRefinement
                      attribute="collections"
                      label="Kollektionen"
                      value={item}
                      defaultRefinement={true}
                      className="d-none"
                    />
                    <Configure hitsPerPage={10} />
                    <h2>{item}</h2>
                    <div className="mb-5">
                      <CustomHitsHSW hitComponent={Hit} className="hsw hide-scrollbar" style={{
                        overflowX: 'scroll',
                      }} />
                    </div>
                  </InstantSearch>
                )}
              </>
            </div>
          </div>
        </div>
        : 
        <div className="container-fluid px-md-3 text-center">
          loading...
        </div>}
    </WithAlgolia>
  );
};

function Hit({hit}) {
  return (
    <div className="product-wrapper">
      <ProductCard product={hit}/>
    </div>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default CollectionsPage;
