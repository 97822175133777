import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as CloseIcon} from 'components/icons/close.svg';
import {ReactComponent as StuffIcon} from 'components/icons/chevron-down.svg';
import {ReactComponent as DownloadIcon} from 'components/icons/download.svg';
import {Document, Page, pdfjs} from 'react-pdf';
import {FormattedMessage} from 'react-intl';
import LoadingBottle from 'components/general/LoadingBottle';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';


export default function PDFPreview(props) {
  const [height, setHeight] = useState(null);
  const [pageNr, setPageNr] = useState(null);
  const [maxPageNr, setMaxPageNr] = useState(null);

  const handleLoadSucccess = ({ numPages }) => {
    setPageNr(1);
    setMaxPageNr(numPages);
  };

  const handleLastPage = () => {
    if (pageNr && pageNr > 1) {
      setPageNr(pageNr - 1);
    }
  };

  const handleNextPage = () => {
    if (pageNr && maxPageNr && pageNr < maxPageNr) {
      setPageNr(pageNr + 1);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>{props.open &&
          <div className="w-100 h-100 d-flex flex-column align-items-stretch bg-dark">
            <div className="position-fixed top-0 d-flex p-3 justify-content-end gap-3" style={{right: 0}}>
              {props.pdf && <>
                <a download="menu.pdf" href={props.pdf} title="Download" className="btn btn-secondary d-flex justify-content-center align-items-center bg-white text-primary">
                  <DownloadIcon />
                </a>
              </>}

              <button onClick={props.onClose} className="btn btn-secondary d-flex justify-content-center align-items-center bg-white text-primary">
                <CloseIcon />
              </button>
            </div>
            {props.pdf && !props.error ?
              <>
                <Document
                  className="w-70 h-100 mx-auto d-inline-block bg-white"
                  file={props.pdf}
                  onLoadSuccess={handleLoadSucccess}
                  onLoadError={console.error}
                >
                  {pageNr != null && <Page height={height || window.innerHeight} pageNumber={pageNr} />}
                </Document>


                <div className="position-fixed bottom-0 w-100 d-flex justify-content-center">
                  <div className="bg-white p-3 d-flex gap-3 align-items-center" style={{boxShadow: '0 .5rem 1rem rgba(0,0,0,.3)'}}>
                    <button
                      className="btn btn-secondary d-flex justify-content-center align-items-center bg-white text-primary"
                      onClick={() => handleLastPage()}
                      style={{opacity: pageNr === 1 ? 0.5 : 1}}
                    >
                      <StuffIcon style={{transform: 'rotate(90deg)'}}/>
                    </button>

                    <span>
                      <FormattedMessage id="pdfpreview.page" />&nbsp;{pageNr}&nbsp;<FormattedMessage id="pdfpreview.of" />&nbsp;{maxPageNr}
                    </span>

                    <button
                      className="btn btn-secondary d-flex justify-content-center align-items-center bg-white text-primary"
                      onClick={() => handleNextPage()}
                      style={{opacity: pageNr === maxPageNr ? 0.5 : 1}}
                    >
                      <StuffIcon style={{transform: 'rotate(-90deg)'}}/>
                    </button>
                  </div>
                </div>
              </>
              :
              props.error ?
                <div className="w-100 h-100 d-flex justify-content-center align-items-center text-white">
                  <span><FormattedMessage id="pdfpreview.error" /></span>
                </div>
                :
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <LoadingBottle className="w-10 h-10 m-auto"/>
                </div>
            }
          </div>
    }</>
  );
}

PDFPreview.propTypes = {
  open: PropTypes.bool,
  error: PropTypes.bool,
  onClose: PropTypes.func,
  pdf: PropTypes.any,
};