import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconSmallArrowLeft } from 'components/icons/small-arrow-left.svg';
import './Slider.scss';

const Slider = ({
  height,
  slides,
}) => {
  const slider = useRef();
  const [activeIndex, _setActiveIndex] = useState(0);
  const activeIndexRef = useRef(activeIndex);
  const setActiveIndex = data => {
    activeIndexRef.current = data;
    _setActiveIndex(data);
  };
  const wrapperStyles = {
    height: height || '350px',
  };
  const getClass = (idx) => {
    if (idx == activeIndex) return 'active';
    if (idx < activeIndex) return 'before';
    if (idx > activeIndex) return 'after';
  };
  const last = () => {
    if (activeIndexRef.current > 0)
      setActiveIndex(activeIndexRef.current - 1);
  };
  const next = () => {
    if (activeIndexRef.current < slides.length - 1)
      setActiveIndex(activeIndexRef.current + 1);
  };
  let touchstartX = 0;
  let touchendX = 0;

  const handleGesture = () => {
    if (touchendX > touchstartX) last();
    if (touchendX < touchstartX) next();
  };

  const [listenerSet, setListenerSet] = useState(false);
  useEffect(() => {
    if (!listenerSet && slider.current) {
      slider.current.addEventListener('touchstart', e => {
        touchstartX = e.changedTouches[0].screenX;
      });

      slider.current.addEventListener('touchend', e => {
        touchendX = e.changedTouches[0].screenX;
        handleGesture();
      });

      setListenerSet(true);
    }
  });

  return(
    <div className="sliderOuterWrapper" style={wrapperStyles}>
      <div ref={slider} className="sliderWrapper">
        {slides && slides.map((slide, key) => (
          <a href={slide.href} key={key}>
            <div className={`slide mb-3 ${getClass(key)}`} style={{backgroundImage: `url(${slide.imgUrl})`}} onClick={() => setActiveIndex(key)}>
              <h1>
                {slide.title}
                <small>{slide.subtitle}</small>
              </h1>
            </div>
          </a>
        ))}
      </div>

      {activeIndex > 0 &&
        <div className="showMoreSliderButtons left"
          onClick={() => last()}>
          <button>
            <IconSmallArrowLeft />
          </button>
        </div>
      }
      {activeIndex < slides.length - 1 &&
        <div className="showMoreSliderButtons right"
          onClick={() => next()}>
          <button>
            <IconSmallArrowLeft />
          </button>
        </div>
      }

      <div className="dotsWrapper">
        {slides && slides.map((slide, key) => (
          <div key={key} className={`dot mb-3 ${getClass(key)}`} onClick={() => setActiveIndex(key)}>
          </div>
        ))}
      </div>
    </div>
  );
};

Slider.propTypes = {
  height: PropTypes.string,
  slides: PropTypes.array,
};

export default Slider;