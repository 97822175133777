import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as BottleLoading } from 'components/icons/bottle-loading.svg';
import './LoadingBottle.scss';

const LoadingBottle = ({
  size = 24,
  color = '#EDEDED',
}) => {
  const style = {
    width: `${size}px`,
    height: `${size}px`,
    color: color,
  };

  return(
    <BottleLoading className="loading-bottle" style={style} />
  );
};

LoadingBottle.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default LoadingBottle;