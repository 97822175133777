import {wineAppAuthedClient} from './wineAppClient';

function currentCart() {
  return wineAppAuthedClient.request({
    method: 'GET',
    url: '/order/currentcart/',
    responseType: 'json'
  }).then(response => response.data);
}

function addToCurrentCart(wineId, quantity) {
  return wineAppAuthedClient.request({
    method: 'POST',
    url: '/order/currentcart/addproduct/',
    data: {
      wineId: wineId,
      quantity: quantity,
    },
    responseType: 'json',
  }).then(response => response.data);
}

function removeFromCurrentCart(wineId) {
  return wineAppAuthedClient.request({
    method: 'POST',
    url: '/order/currentcart/deleteproduct/',
    responseType: 'json',
    data: {
      wineId: wineId
    }
  }).then(response => response.data);
}

function submitCurrentCart(soldTo, shipTo, deliveryDate, comment) {
  return wineAppAuthedClient.request({
    method: 'POST',
    url: '/order/currentcart/submit/',
    responseType: 'json',
    data: {
      soldTo: soldTo,
      shipTo: shipTo,
      deliveryDate: deliveryDate,
      comment: comment,
    }
  }).then(response => response.data);
}

function fetchDeliveryDates(soldTo) {
  return wineAppAuthedClient.request({
    method: 'GET',
    url: `/order/deliverydates/${soldTo}/`,
    responseType: 'json',
  }).then(response => response.data);
}

export const cartApi = {
  currentCart,
  addToCurrentCart,
  removeFromCurrentCart,
  submitCurrentCart,
  fetchDeliveryDates,
};