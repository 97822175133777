import React from 'react';
import LoadingBottle from './LoadingBottle';
import './ProductCard.scss';

const ProductCardLoading = () => {
  return(
    <div className="product loading">
      <LoadingBottle size={145} />
    </div>
  );
};

export default ProductCardLoading;