import {isNil} from 'ramda';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {authApi} from 'api/authApi';
import jwt_decode from 'jwt-decode';

const LOCAL_STORAGE_AUTH_INFO_KEY = 'auth';

const selectors = {
  username: state => state.auth.username,
  accessToken: state => state.auth.accessToken,
  refreshToken: state => state.auth.refreshToken,
  triedRefresh: state => state.auth.triedRefresh,
  isSalesUser: state => state.auth.isSales,
  customer: state => state.auth.customer
};

selectors.loggedIn = state => {
  const accessToken = selectors.accessToken(state);
  if (isNil(accessToken)) {
    return false;
  }

  try {
    const expInSeconds = jwt_decode(accessToken).exp;
    const nowInSeconds = Math.floor(Date.now() / 1000);
    return nowInSeconds < expInSeconds;
  } catch (e) {
    console.error('could not decode jwt token', e);
    return false;
  }
};


const login = createAsyncThunk(
  'auth/login',
  (credentials) => authApi.login(credentials.username, credentials.password)
);

const refresh = createAsyncThunk(
  'auth/refresh',
  (test, thunk) => {
    const state = thunk.getState();
    const alreadyTriedRefresh = selectors.triedRefresh(state);
    if (alreadyTriedRefresh) {
      return Promise.reject();
    }

    const refreshToken = selectors.refreshToken(state);
    // can be null if logged in via customer app url params, where we don't get a refresh token
    if (isNil(refreshToken)) {
      return Promise.reject();
    }

    return authApi.refresh(refreshToken);
  }
);

const _initialLocalStorageInfo = getAuthInfoFromLocalStorage();
const _decodedToken = _initialLocalStorageInfo.accessToken ? jwt_decode(_initialLocalStorageInfo.accessToken) : null;

const {actions, reducer} = createSlice({
  name: 'auth',
  initialState: {
    ...(getAuthInfoFromLocalStorage()),
    customer: getCustomerNoFromAuthToken(_decodedToken),
    isSales: isSalesUser(_decodedToken),
    triedRefresh: false,
    requests: {
      login: {
        loading: false,
        error: null
      },
      refresh: {
        loading: false,
        error: null
      }
    }
  },
  reducers: {
    tokenLogin: (state, action) => {
      onSuccessfulLogin(state, {
        access_token: action.payload.token,
        refresh_token: null,
        user_roles: action.payload.userRoles
      });
    },
    logout: (state) => {
      state.triedRefresh = false;
      onLogout(state);
    },
    setCustomer: (state, action) => {
      setCustomer(state, action.payload.customer);
    }
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.requests.login.loading = true;
      state.requests.login.error = null;
      state.triedRefresh = false;
      state.accessToken = null;
      state.refreshToken = null;
    },
    [login.fulfilled]: (state, action) => {
      state.requests.login.loading = false;
      state.requests.login.error = null;
      onSuccessfulLogin(state, action.payload);
    },
    [login.rejected]: (state, action) => {
      state.requests.login.loading = false;
      state.requests.login.error = action.error.message;
      state.triedRefresh = false;
      onLogout(state);
    },

    [refresh.pending]: (state) => {
      state.requests.refresh.loading = true;
      state.requests.refresh.error = null;
      state.accessToken = null;
    },
    [refresh.fulfilled]: (state, action) => {
      state.requests.refresh.loading = false;
      state.requests.refresh.error = false;
      onSuccessfulLogin(state, action.payload);
    },
    [refresh.rejected]: (state, action) => {
      state.requests.refresh.loading = false;
      state.requests.refresh.error = action.error.message;
      state.triedRefresh = true;
      onLogout(false);
    }
  }
});

function onSuccessfulLogin(state, response) {
  setStateFromSuccessfulAuthResponse(state, response);
  saveAuthInfoToLocalStorage({
    accessToken: response.access_token,
    refreshToken: response.refresh_token
  });
}

function setCustomer(state, customer) {
  state.customer = customer;
}

function onLogout(state) {
  clearAuthInfoFromLocalStorage();
  setLoggedOutState(state);
}

function setStateFromSuccessfulAuthResponse(state, response) {
  state.triedRefresh = false;
  state.accessToken = response.access_token;
  state.refreshToken = response.refresh_token;
  state.username = response.username;

  const decodedToken = jwt_decode(response.access_token);
  state.customer = getCustomerNoFromAuthToken(decodedToken);

  if(response.user_roles) {
    state.isSales = isSalesUserByRoles(response.user_roles);
  } else {
    state.isSales = isSalesUser(decodedToken);
  }

}

function getAuthInfoFromLocalStorage() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTH_INFO_KEY))
      ?? {
        accessToken: null,
        refreshToken: null,
        username: null
      };
}

function saveAuthInfoToLocalStorage(authState) {
  localStorage.setItem(LOCAL_STORAGE_AUTH_INFO_KEY, JSON.stringify(authState));
}

function clearAuthInfoFromLocalStorage() {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_INFO_KEY);
}

function setLoggedOutState(state) {
  state.accessToken = null;
  state.refreshToken = null;
  state.triedRefresh = false;
  state.username = null;
  state.isSales = false;
  state.customer = null;
}

function isSalesUser(decodedToken) {
  const roles = decodedToken?.roles ?? [];
  return isSalesUserByRoles(roles);
}

function isSalesUserByRoles(roles) {
  return roles.includes('ROLE_TEST_USER') || roles.includes('ROLE_SUPERUSER');
}

function getCustomerNoFromAuthToken(decodedToken) {
  return isSalesUser(decodedToken) ? null : (decodedToken?.sub ?? null);
}

const publicActions = {
  login,
  tokenLogin: actions.tokenLogin,
  logout: actions.logout,
  setCustomer: actions.setCustomer,
  refresh
};

export {publicActions as authActions};

const publicSelectors = {
  username: selectors.username,
  loggedIn: selectors.loggedIn,
  accessToken: selectors.accessToken,
  customer: selectors.customer,
  isSalesUser: selectors.isSalesUser
};

export {publicSelectors as authSelectors};

export {reducer as authReducer};
