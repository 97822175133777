export const getRequestStatus = (requestStatus, successCondition) => {
  let status = 'idle';
  if (requestStatus.loading) {
    status = 'loading';
  } else if (requestStatus.error !== null) {
    status = 'error';
  } else if (successCondition) {
    status = 'success';
  }
  return {
    status: status,
    msg: requestStatus.error,
  };
};