import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconTopSeller } from 'components/icons/top-seller.svg';
import { ReactComponent as IconBio } from 'components/icons/bio.svg';
import { ReactComponent as IconSoonOutOfStock } from 'components/icons/soon-out-of-stock.svg';
import { ReactComponent as IconLimitedEdition } from 'components/icons/limited-edition.svg';
// import { ReactComponent as IconStar } from 'components/icons/star.svg';
import './ProductBadge.scss';
import {FormattedMessage} from 'react-intl';

const ProductBadge = ({
  type,
  text = null,
}) => {
  return(
    <>
      {{
        vegan: <span className={`badge-wineapp ${type}`}>
          <IconBio />
          <FormattedMessage
            id= "ProductBadge.Vegan"
          />
        </span>,
        bio: <span className={`badge-wineapp ${type}`}>
          <IconBio />
          <FormattedMessage
            id= "ProductBadge.Bio"
          />
        </span>,
        bestSeller: <span className={`badge-wineapp ${type}`}>
          <IconTopSeller />
          <FormattedMessage
            id= "ProductBadge.Bestseller"
          />
        </span>,
        topWine: <span className={`badge-wineapp ${type}`}>
          <IconTopSeller />
          <FormattedMessage id="ProductBadge.TopWine" />
        </span>,
        soonOutOfStock: <span className={`badge-wineapp ${type}`}>
          <IconSoonOutOfStock />
          <FormattedMessage
            id= "ProductBadge.Restposten"
          />
        </span>,
        outOfStock: <span className={`badge-wineapp ${type}`}>
          <span><FormattedMessage id="ProductBadge.OutOfStock"/></span>
        </span>,
        nonExclusive: <span className={`badge-wineapp ${type}`}>
          <FormattedMessage
            id= "ProductBadge.NichtExklusiv"
          />
        </span>,
        limitedEdition: <span className={`badge-wineapp ${type}`}>
          <IconLimitedEdition />
          <FormattedMessage id="ProductBadge.LimitedEdition"/>
        </span>,
        newInSortiment: <span className={`badge-wineapp ${type}`}>
          <FormattedMessage
              id= "ProductBadge.NewInSortiment"
          />
        </span>,
        discount: <span className={`badge-wineapp ${type}`}>
          {text}
        </span>,
        alcoholFree: <span className={`badge-wineapp ${type}`}>
          <FormattedMessage
            id= "ProductBadge.AlcoholFree"
          />
        </span>,
      }[type]}
    </>
  );
};

ProductBadge.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default ProductBadge;