import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {
  Configure,
  Index,
  InstantSearch,
  SortBy,
  ToggleRefinement,
} from 'react-instantsearch-dom';
import qs from 'qs';

import {algoliaCredentialsSelectors} from '../../features/algoliaCredentialsSlice';
import {CustomCurrentRefinements} from '../algolia/CustomCurrentRefinements.js';
import {CustomHits} from '../algolia/CustomHits.js';
import {CustomPagination} from '../algolia/CustomPagination.js';
import {CustomRangeSlider} from '../algolia/CustomRangeSlider.js';
import {CustomRefinementList} from '../algolia/CustomRefinementList.js';
import {NumberOfHits} from '../algolia/NumberOfHits.js';
import ProductCard from '../general/ProductCard.js';
import {ReactComponent as IconClose} from 'components/icons/close.svg';
import { ReactComponent as IconSmallArrowLeft } from 'components/icons/small-arrow-left.svg';
import Recommendation from 'components/general/Recommendation.js';
import {WithAlgolia, useAlgoliaClient} from '../algolia/WithAlgolia';
import './ProductListPage.scss';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import Collapsible from 'components/general/Collapsible';
import Modal from 'components/general/Modal';
import LoadingBottle from 'components/general/LoadingBottle';
import { recommendationSelectors } from 'features/recommendationSlice';
import { CustomClearRefinements } from 'components/algolia/CustomClearRefinements';
import {useIntl} from 'react-intl';
import { authSelectors } from 'features/authSlice';
import { userSelectors } from 'features/userSlice';
// import { set } from 'date-fns';

const qsFormat = {
  stringify: {
    arrayFormat: 'comma',
    allowDots: true,
    format: 'RFC1738' // spaces to '+'
  },
  parse: {
    comma: true,
    allowDots: true
  }
};

function clean(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || (propName == 'page' && obj[propName] == 1)) {
      delete obj[propName];
    }
    if(typeof obj[propName] === 'object') {
      clean(obj[propName]);
    }
  }
  return obj;
}
const urlToSearchState = (urlSearch) => qs.parse(urlSearch.slice(1), qsFormat.parse);
const searchStateToUrl = (searchState, url = '#/products/all') => {
  const { configure, ...noConfigure } = searchState;
  // TODO: workaround because no-used variables are not allowed...
  configure;
  // WARNING: add #/products is dangerous but a simple workaround for the 
  //          conflict with the HashRouter
  // TODO: is cleaning done propperly?
  searchState = clean(searchState);
  return !searchState ? '' : `?${qs.stringify(noConfigure, qsFormat.stringify)}${url}`;
};
function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

const ProductListPage = ({
  location,
  listPageType = 'all',
}) => {
  let {startRecommendation} = useParams();
  const algoliaClient = useAlgoliaClient();
  const productIndexName = useSelector(algoliaCredentialsSelectors.productIndex);
  const recommendationIndexName = 'prod-wine-recommend';//useSelector(algoliaCredentialsSelectors.recommendationIndexName);
  const query = useSelector(state => state.search.query);
  const recommendationActive = useSelector(recommendationSelectors.recommendationActive);
  // const plz = useSelector(state => state.recommendation.plz);
  const plzInfo = useSelector(recommendationSelectors.plzInfo);
  const cluster = plzInfo ? plzInfo.cluster.id : null;
  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const language = useSelector(userSelectors.language);
  let browserHistory = useHistory();


  const urlSearch = window.location.search;
  const [searchState, setSearchState] = useState(urlToSearchState(urlSearch));
  const onSetSearchState = debounce((searchState) => {
    window.history.pushState({}, 'WineApp Search', searchStateToUrl(searchState, {
      all: '#/products/all',
      exclusive: '#/products/exclusive',
      nonExclusive: '#/products/selection',
    }[listPageType]));
    setSearchState(searchState);
  });


  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openCollapsibleCountry, setOpenCollapsibleCountry] = useState(false);
  const [openCollapsibleRegion, setOpenCollapsibleRegion] = useState(false);
  const [openCollapsibleType, setOpenCollapsibleType] = useState(false);
  const [openCollapsibleGrapes, setOpenCollapsibleGrapes] = useState(false);
  const [openCollapsibleTaste, setOpenCollapsibleTaste] = useState(false);
  const [openCollapsibleStyle, setOpenCollapsibleStyle] = useState(false);
  const [openCollapsiblePrice, setOpenCollapsiblePrice] = useState(false);
  const [openCollapsibleSuitableFood, setOpenCollapsibleSuitableFood] = useState(false);
  const [openCollapsibleBestSeller, setOpenCollapsibleBestSeller] = useState(false);
  const [openCollapsibleBioVegan, setOpenCollapsibleBioVegan] = useState(false);
  const [openCollapsibleAlcoholContent, setOpenCollapsibleAlcoholContent] = useState(false);
  const [openCollapsibleClosure, setOpenCollapsibleClosure] = useState(false);
  const [openCollapsibleAgeingContainer, setOpenCollapsibleAgeingContainer] = useState(false);
  const [openCollapsibleAgeingTime, setOpenCollapsibleAgeingTime] = useState(false);
  const [openCollapsibleBottleSize, setOpenCollapsibleBottleSize] = useState(false);
  const [openCollapsibleCollections, setOpenCollapsibleCollections] = useState(false);
  const intl = useIntl();


  const refinementsList = {
    country: {
      name: intl.formatMessage({id: 'Refinement.Country'}),
      open: openCollapsibleCountry,
      setOpen: setOpenCollapsibleCountry,
      refinementList: <CustomRefinementList
        attribute={{
          de: 'country',
          fr: 'countryFr',
          it: 'countryIt',
        }[language]}
        searchable
        showMore
        limit={5}
        showMoreLimit={20}
        title={intl.formatMessage({id: 'Refinement.Country'})} />,
    },
    region: {
      name: intl.formatMessage({id: 'Refinement.Region'}),
      open: openCollapsibleRegion,
      setOpen: setOpenCollapsibleRegion,
      refinementList: <CustomRefinementList
        attribute={{
          de: 'region',
          fr: 'regionFr',
          it: 'regionIt',
        }[language]}
        searchable
        showMore
        limit={5}
        showMoreLimit={20}
        title={intl.formatMessage({id: 'Refinement.Region'})} />,
    },
    category: {
      name: intl.formatMessage({id: 'Refinement.Weintyp'}),
      open: openCollapsibleType,
      setOpen: setOpenCollapsibleType,
      refinementList: <CustomRefinementList
        attribute={{
          de: 'category',
          fr: 'categoryFr',
          it: 'categoryIt',
        }[language]}
        title={intl.formatMessage({id: 'Refinement.Weintyp'})}
        isWinetype />,
    },
    grapes: {
      name: intl.formatMessage({id: 'Refinement.Rebsorte'}),
      open: openCollapsibleGrapes,
      setOpen: setOpenCollapsibleGrapes,
      refinementList: <CustomRefinementList
        attribute="grapes.grapes"
        searchable
        showMore
        limit={5}
        showMoreLimit={20}
        title={intl.formatMessage({id: 'Refinement.Rebsorte'})} />,
    },
    sweetness: {
      name: intl.formatMessage({id: 'Refinement.Süssegrad'}),
      open: openCollapsibleTaste,
      setOpen: setOpenCollapsibleTaste,
      refinementList: <CustomRangeSlider
        attribute="sweetness"
        title={intl.formatMessage({id: 'Refinement.Süssegrad'})}
        dots
        texts={{left: intl.formatMessage({id: 'Refinement.Trocken'}), right: intl.formatMessage({id: 'Refinement.Lieblich'})}} />,
    },
    taste: {
      name: intl.formatMessage({id: 'Refinement.Schweregrad'}),
      open: openCollapsibleStyle,
      setOpen: setOpenCollapsibleStyle,
      refinementList: <CustomRangeSlider
        attribute="taste"
        title={intl.formatMessage({id: 'Refinement.Schweregrad'})}
        dots
        texts={{left: intl.formatMessage({id: 'Refinement.Leicht'}), right: intl.formatMessage({id: 'Refinement.Schwer'})}} />,
    },
    price: isLoggedIn && !recommendationActive ? {
      name: intl.formatMessage({id: 'Refinement.Preis'}),
      open: openCollapsiblePrice,
      setOpen: setOpenCollapsiblePrice,
      refinementList: <CustomRangeSlider
        attribute="sale_price_per_bottle"
        title={intl.formatMessage({id: 'Refinement.Preis'})}
        unit="CHF"
        priceButtons={true} />,
    } : null,
    suitableFood: {
      name: intl.formatMessage({id: 'Refinement.PasstZu'}),
      open: openCollapsibleSuitableFood,
      setOpen: setOpenCollapsibleSuitableFood,
      refinementList: <CustomRefinementList
        attribute={{
          de: 'suitableFood',
          fr: 'suitableFoodFr',
          it: 'suitableFoodIt',
        }[language]}
        searchable
        showMore
        limit={5}
        showMoreLimit={20}
        title={intl.formatMessage({id: 'Refinement.PasstZu'})} />,
    },
    bioVegan: {
      name: intl.formatMessage({id: 'Refinement.Vegan'}),
      open: openCollapsibleBioVegan,
      setOpen: setOpenCollapsibleBioVegan,
      refinementList: <CustomRefinementList
        attribute="bioVegan"
        title={intl.formatMessage({id: 'Refinement.Vegan'})} />,
    },
    bestSeller: {
      name: intl.formatMessage({id: 'Refinement.bestSeller'}),
      open: openCollapsibleBestSeller,
      setOpen: setOpenCollapsibleBestSeller,
      refinementList: <ToggleRefinement
        attribute="flags"
        label={intl.formatMessage({id: 'Refinement.bestSeller'})}
        value="bestSeller"
        defaultRefinement={false}
        className="m-3 toggle-refinement"
      />,
    },
    alcoholContent: {
      name: intl.formatMessage({id: 'Refinement.Alkoholgehalt'}),
      open: openCollapsibleAlcoholContent,
      setOpen: setOpenCollapsibleAlcoholContent,
      refinementList: <CustomRangeSlider
        attribute="alcoholContent"
        title={intl.formatMessage({id: 'Refinement.Alkoholgehalt'})} />,
    },
    closure: {
      name: intl.formatMessage({id: 'Refinement.Verschluss'}),
      open: openCollapsibleClosure,
      setOpen: setOpenCollapsibleClosure,
      refinementList: <CustomRefinementList
        attribute={{
          de: 'closure',
          fr: 'closureFr',
          it: 'closureIt',
        }[language]}
        title={intl.formatMessage({id: 'Refinement.Verschluss'})} />,
    },
    ageingContainer: {
      name: intl.formatMessage({id: 'Refinement.ageingContainer'}),
      open: openCollapsibleAgeingContainer,
      setOpen: setOpenCollapsibleAgeingContainer,
      refinementList: <>
        <CustomRefinementList
          attribute="ageingContainer"
          tansformLabel={(label) => {
            if (label === "true") return intl.formatMessage({id: "Refinement.ageingContainer.oak"});
            return intl.formatMessage({id: "Refinement.ageingContainer.steel"});
          }}
          title={intl.formatMessage({id: 'Refinement.ageingContainer'})}
        />
      </>,
    },
    ageingTime: {
      name: intl.formatMessage({id: 'Refinement.ageingTime'}),
      open: openCollapsibleAgeingTime,
      setOpen: setOpenCollapsibleAgeingTime,
      refinementList: <CustomRangeSlider
        attribute="ageingTime"
        title={intl.formatMessage({id: 'Refinement.ageingTime'})} />,
    },
    bottleSize: {
      name: intl.formatMessage({id: 'Refinement.Flaschengrösse'}),
      open: openCollapsibleBottleSize,
      setOpen: setOpenCollapsibleBottleSize,
      refinementList: <CustomRefinementList
        attribute="bottle_size"
        title={intl.formatMessage({id: 'Refinement.Flaschengrösse'})}
        unit="cl"
      />,
    },
    collection: {
      name: intl.formatMessage({id: 'Refinement.Kollektionen'}),
      open: openCollapsibleCollections,
      setOpen: setOpenCollapsibleCollections,
      refinementList: <CustomRefinementList
        attribute="collections"
        title={intl.formatMessage({id: 'Refinement.Kollektionen'})}
      />,
    },
  };

  const filterModalBody = useRef();

  const closeAllCollapibles = () => {
    for (const [key, value] of Object.entries(refinementsList)) {
      key;
      value?.setOpen(false);
    }
  };

  const scrollToId = (id) => {
    setTimeout(function() {
      const wrapper = filterModalBody.current?.parentElement;
      if (document.getElementById(id)) {
        const offsetY = document.getElementById(id).offsetTop;
        wrapper.scrollTo({
          top: offsetY - 90,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  const isRefined = (filter) => {
    return urlSearch.includes(filter);
  };

  useEffect(() => {
    if (location.state && location.state.filter && document.getElementById(location.state.filter)) {
      if (window.innerWidth < 768) {
        closeAllCollapibles();
        setOpenFilterModal(true);
        scrollToId(location.state.filter);
      }
      refinementsList[location.state.filter].setOpen(true);
      location.state.filter = null;
      setTimeout(() => setSearchState({}), 500);
    }
  });

  const handleResize = () => window.innerWidth >= 768 && setOpenFilterModal(false);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    window.scrollTo(0,0);
  }, [searchState.page]);

  useEffect(() => {
    setTimeout(() => setSearchState({...searchState, ...searchState.page = 1}), 500);
  }, [query]);

  useEffect(() => {
    setSearchState({...searchState, page: 1});
    onSetSearchState({...searchState, page: 1});
  }, [listPageType]);

  const listPageTypes = {
    all: '',
    exclusive: 'AND NOT flags:nonExclusive',
    nonExclusive: 'AND flags:nonExclusive',
  };

  return (
    <WithAlgolia>
      <div className="container-fluid px-md-3">
        <div className="row">
          {algoliaClient && productIndexName && recommendationIndexName &&
            <InstantSearch
              indexName={productIndexName}
              searchClient={algoliaClient}
              createURL={searchState => `?q=${searchState.query}`}
              searchState={searchState}
              onSearchStateChange={onSetSearchState}
            >
              <Configure
                hitsPerPage={!recommendationActive ? 12 : 5}
                query={query}
                filters={`NOT flags:unavailableTheBottle ${listPageTypes[listPageType] || ''}`}
              />

              <div className="col-xs-12 col-md-3">
                {algoliaClient && productIndexName ? <>
                  <div className="refinement-buttons hide-scrollbar">
                    {Object.entries(refinementsList).map(([index, value]) =>
                      value && <button
                        key={index}
                        className={isRefined(index) ? 'yes' : ''}
                        onClick={() => {
                          closeAllCollapibles();
                          setOpenFilterModal(true);
                          value.setOpen(true);
                          scrollToId(index);
                        }}>
                        {value.name}
                      </button>
                    )}
                  </div>

                  <Modal
                    name="filter"
                    open={openFilterModal}
                    setOpen={setOpenFilterModal}
                    closable={true}
                    size="small"
                    shadow={false}
                    position="middle-side"
                    usePortal={false}
                    placeholderBody={true}
                    title={<>
                      <IconClose className="me-3" onClick={() => setOpenFilterModal(false)}/>
                      Filter
                    </>}
                    body={
                      <div ref={filterModalBody} id="filter-modal-body" className="filter-modal-body">
                        <div className="d-block d-md-none">
                          <CustomCurrentRefinements placeholder />
                        </div>

                        <>
                          {Object.entries(refinementsList).map(([index, value]) =>
                            value && <Collapsible 
                              key={index}
                              open={value.open}
                              setOpen={value.setOpen}
                              id={index}
                              title={value.name}
                              titleClassName={isRefined(index) ? 'p-3 fw-bold' : 'p-3'}
                              body={value.refinementList}
                            />
                          )}
                        </>
                      </div>
                    }
                    foot={<>
                      <CustomClearRefinements
                        className="light"
                        onClick={() => setOpenFilterModal(false)}
                        translations={{
                          reset: 'Entfernen',
                        }} />
                      <button
                        className="primary"
                        onClick={() => setOpenFilterModal(false)}>
                        Anwenden
                      </button>
                    </>}
                  />
                </> : <>
                  <div className="d-flex justify-content-center">
                    <LoadingBottle />
                  </div>
                </>}
              </div>
              <div className="col-xs-12 col-md-9">
                {algoliaClient && productIndexName ? <>
                  <CustomCurrentRefinements/>
                  {isLoggedIn && listPageType == 'exclusive' && <Recommendation startRecommendation={startRecommendation} />}
                  <div className="px-3 px-md-0">
                    {!recommendationActive ?
                      <>
                        <div className="d-flex justify-content-between gap-5 align-items-center">
                          <div>
                            <NumberOfHits listName={<select onChange={(event) => browserHistory.push(`/products/${event.target.value}`)} className="form-select d-inline w-auto">
                              <option value="all" selected={listPageType == 'all'}>Alle</option>
                              <option value="exclusive" selected={listPageType == 'exclusive'}>EXCLUSIVE</option>
                              <option value="selection" selected={listPageType == 'nonExclusive'}>SELECTION</option>
                            </select>}
                            />
                          </div>
                          <div className="sort-by">
                            <SortBy
                              id="sort-by-select"
                              defaultRefinement={productIndexName}
                              items={[
                                {value: productIndexName, label: intl.formatMessage({id: 'Sort.Relevanz'})},
                                {value: productIndexName + '-price-asc', label: intl.formatMessage({id: 'Sort.günstigstes'})},
                                {value: productIndexName + '-price-desc', label: intl.formatMessage({id: 'Sort.teuerstes'})},
                              ]}
                            />
                            <label htmlFor="sort-by-select">
                              <IconSmallArrowLeft />
                            </label>
                          </div>
                        </div>

                        <CustomHits hitComponent={Hit}/>

                        <div className="pagination mt-5">
                          <CustomPagination/>
                        </div>
                      </>
                      :
                      <>
                        {Object.entries({
                          white: intl.formatMessage({id: 'Refinement.Weisswein'}),
                          rose: intl.formatMessage({id: 'Refinement.Rose'}),
                          red: intl.formatMessage({id: 'Refinement.Rotwein'}),
                          champagne: intl.formatMessage({id: 'Refinement.Schaumwein'}),
                        }).map(([key, index]) =>
                          <Index key={key} indexName={`${recommendationIndexName}-${key}-${cluster}`}>
                            <CustomHits hitComponent={Hit} title={index} doNotShowOnEmpty={true} />
                          </Index>
                        )}
                      </>
                    }
                  </div>
                </> : <div className="d-flex justify-content-center">
                  <LoadingBottle />
                </div>}
              </div>
            </InstantSearch>
          }
        </div>
      </div>
    </WithAlgolia>
  );
};

ProductListPage.propTypes = {
  location: PropTypes.object,
  listPageType: PropTypes.string,
};

function Hit(props) {
  return (
    <div className="col-xs-12 col-md-6 col-lg-6 col-xl-4 my-3">
      <ProductCard product={props.hit}/>
    </div>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default ProductListPage;
