import AppStoreBadge from 'components/icons/app-store-badge.png';
import PlayStoreBadgeImage from 'components/icons/play-store-badge.png';
import Modal from 'components/general/Modal';
import {ReactComponent as IconClose} from 'components/icons/close.svg';
import {FormattedMessage} from 'react-intl';
import QRCode from 'qrcode.react';
import React, {useRef, useState} from 'react';
import './AppStoreLinks.scss';

const APPLE_STORE_LINK = 'https://apps.apple.com/fr/app/feldschl%C3%B6sschen-cockpit/id1354436736?l=en';
const GOOGLE_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.sobr.customerapp&hl=en';

export default function AppStoreLinks() {
  const modal = useRef();
  const [open, setOpen] = useState(false);
  const [appStore, setAppStore] = useState('Apple');

  const handleAppStoreClick = (store) => {
    setOpen(true);
    setAppStore(store);
  };

  return <>
    <div className="app-store-links d-flex justify-content-center">
      <div className="align-content-center">
        <img src={AppStoreBadge} onClick={() => handleAppStoreClick('Apple')}/>
      </div>

      <div className="align-content-center">
        <img src={PlayStoreBadgeImage} onClick={() => handleAppStoreClick('Google')}/>
      </div>
    </div>

    <Modal
      name="app store footer"
      modal={modal}
      open={open}
      setOpen={setOpen}
      position="middle-side"
      size="medium"
      title={{
        'Apple': <>
          <IconClose className="me-3" onClick={() => setOpen(false)}/>
          App Store
        </>,
        'Google': <>
          <IconClose className="me-3" onClick={() => setOpen(false)}/>
          Google Play Store
        </>,
      }[appStore]}
      body={
        <div className="appstore-modal-body">
          {{
            'Apple': <>
              <div>
                <FormattedMessage
                  id="Footer.TheBottle.appstore.scan"
                  values={{ts: Date.now()}}
                />
              </div>
              <a href={APPLE_STORE_LINK}>
                <FormattedMessage
                  id="Footer.TheBottle.appstore.store"
                  values={{ts: Date.now()}}
                />
              </a>
              <QRCode value={APPLE_STORE_LINK} renderAs='svg' size={200}/>
            </>,
            'Google': <>
              <div>
                <FormattedMessage
                  id="Footer.TheBottle.google.scan"
                  values={{ts: Date.now()}}
                />
              </div>
              <a href={GOOGLE_STORE_LINK}>
                <FormattedMessage
                  id="Footer.TheBottle.google.store"
                  values={{ts: Date.now()}}
                />
              </a>
              <QRCode value={GOOGLE_STORE_LINK} renderAs='svg' size={200}/>
            </>,
          }[appStore]}
        </div>
      }
      foot={<></>}
    />
  </>;
}