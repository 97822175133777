import React from 'react';
import './RegisterPage.scss';

import {ReactComponent as IconLogo} from 'components/icons/logo.svg';

import {FormattedMessage} from 'react-intl';
import AppStoreLinks from 'components/general/AppStoreLinks';


const RegisterPage = () => {
  return (
    <div className="register-page">
      <IconLogo class="logo"/>
      <p>
        <FormattedMessage id="RegisterPage.Anleitung"/>
      </p>

      <div className="register-app-store-wrapper">
        <AppStoreLinks />
      </div>
    </div>
  );
};

export default RegisterPage;
