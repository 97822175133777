import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchHistory,
  addToHistory,
  deleteFromHistory,
  fetchRecommendations,
  setQuery,
  searchSelectors
} from 'features/searchSlice';
import { ReactComponent as IconTime } from 'components/icons/time.svg';
import { ReactComponent as IconSearch } from 'components/icons/search.svg';
import { ReactComponent as IconClose } from 'components/icons/close.svg';
import { ESC, ENTER, callOnClickOutside, callOnKey } from 'helper/input';
import {authSelectors} from '../../features/authSlice';
import LoadingBottle from 'components/general/LoadingBottle';
import { ReactComponent as IconSmallArrowLeft } from 'components/icons/small-arrow-left.svg';
import './Search.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import { useAlgoliaProductIndex } from 'components/algolia/WithAlgolia';
import { ReactComponent as Bottle } from 'components/icons/bottle.svg';


const Search = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  let browserHistory = useHistory();
  const isOnSearchPage = location.pathname.startsWith('/products');
  const input = useRef();
  const [open, setOpen] = useState(false);

  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const query = useSelector(searchSelectors.query);

  const history = useSelector(searchSelectors.history);
  const historyRequest = useSelector(searchSelectors.historyRequest);

  useEffect(() => {
    if (isLoggedIn && historyRequest.status === 'idle') {
      dispatch(fetchHistory());
    }
  }, [historyRequest]);
  const historyNeedsRefresh = useSelector(searchSelectors.historyNeedsRefresh);
  useEffect(() => {
    if (isLoggedIn && historyNeedsRefresh) {
      dispatch(fetchHistory());
    }
  }, [historyNeedsRefresh]);
  
  const recommendations = useSelector(searchSelectors.recommendations);
  const recommendationsRequest = useSelector(searchSelectors.recommendationsRequest);
  const intl = useIntl();
  useEffect(() => {
    if (recommendationsRequest.status === 'idle') {
      dispatch(fetchRecommendations({isLoggedIn: isLoggedIn}));
    }
  }, [recommendationsRequest]);

  useEffect(() => callOnKey(ESC, () => open && setOpen(false)));
  useEffect(() => callOnClickOutside(input, () => open && setOpen(false)));
  useEffect(() => !open && input.current.blur(), [open]);
  useEffect(() => callOnKey(ENTER, () => {
    open && !isOnSearchPage && browserHistory.push('/products/all');
    setOpen(false);
  }));

  const productIndex = useAlgoliaProductIndex();
  const productIndexReady = productIndex && productIndex.appId;
  const [fastResults, setFastResults] = useState([]);

  useEffect(() => {
    if (productIndexReady && query != '') {
      productIndex.search(query, {
        hitsPerPage: 5,
        filters: 'NOT flags:unavailableTheBottle',
      }).then(({ hits }) => {
        setFastResults(hits);
      });
    }
  }, [query]);

  return(
    <>
      <div className="d-block d-md-none">
        <IconSearch onClick={() => setOpen(true)} />
      </div>
      <form className={open ? 'searchbar active' : 'searchbar'} onSubmit={(ev) => ev.preventDefault()}>
        <div className="pt-5 p-3 p-md-0">
          <div className="input-group">
            <span className="input-group-text close">
              <IconClose onClick={() => {
                dispatch(setQuery({query: ''}));
                input.current.focus();
              }} />
            </span>

            <input
              ref={input}
              className="form-control"
              type="text"
              placeholder={intl.formatMessage({id: 'Search.Wonach'})}
              value={query}
              onFocus={() => setOpen(true)}
              onBlur={() => query != '' && dispatch(addToHistory({query: query}))}
              onChange={(ev) => dispatch(setQuery({query: ev.target.value}))} />

            <span className="input-group-text search">
              <IconSearch onClick={() => {
                if (!open) input.current.focus();
                if (!isOnSearchPage) browserHistory.push('/products/all');
              }} />
            </span>
          </div>
        </div>

        <div className={open ? 'hitlist active' : 'hitlist'}>
          {query != '' && <>
            {fastResults.map((product, index) => product && (
              <Link key={index} className="hit" to={'/product/' + product.objectID}>
                <div style={{overflow: 'hidden'}}>
                  {product.img_url ?
                    <img src={product.img_url} alt={product.name} style={{height: '30px'}} />
                    :
                    <Bottle style={{width: '34px'}} />
                  }
                  
                  {product.name}, <i>{[product.region, product.country].filter(Boolean).join(', ')}</i>
                </div>
                <IconSmallArrowLeft />
              </Link>
            ))}
            <Link className="hit" to="/products/all">
              <i>Alle Resultate anzeigen</i>
              <IconSmallArrowLeft />
            </Link>
          </>}

          {isLoggedIn && <div className="hit title">
            <FormattedMessage
              id="Search.vorherig"
              values={{ts: Date.now()}}
            />
          </div>}
          {{
            'loading':
              <div className="hit"><LoadingBottle /></div>,
            'success':
              history !== null && history.length > 0 && history.map((item, index) => (
                <div key={index} className="hit">
                  <IconTime width="15" height="15" />
                  <span className="ms-1 flex-grow-1" onClick={() => {
                    dispatch(setQuery({query: item}));
                    !isOnSearchPage && setOpen(true);
                  }}>{item}</span>
                  <IconClose width="15" height="15" onClick={() => {
                    dispatch(deleteFromHistory({query: item}));
                    setOpen(true);
                  }} />
                </div>
              )) || <div className="hit"><i>
                <FormattedMessage
                  id="Search.KeineVorherig"
                  values={{ts: Date.now()}}
                />

              </i>
              </div>,
            'error':
              <div className="hit"><i>
                <FormattedMessage
                  id="Search.FehlerVorherig"
                  values={{ts: Date.now()}}
                />
              </i>
              </div>,
          }[historyRequest.status]}


          {(recommendationsRequest.status == 'loading' || recommendationsRequest.status == 'success') && <div className="hit title">
            <FormattedMessage
              id="Search.Beliebig"
              values={{ts: Date.now()}}
            />
          </div>}
          {{
            'loading':
              <div className="hit"><LoadingBottle /></div>,
            'success':
              recommendations !== null && recommendations.length > 0 && recommendations.map((item, index) => (
                <div key={index} className="hit" onClick={() => {
                  dispatch(setQuery({query: item}));
                  !isOnSearchPage && setOpen(true);
                }}>
                  {item}
                </div>
              )) || <div className="hit"><i>
                <FormattedMessage
                  id="Search.KeineBeliebig"
                  values={{ts: Date.now()}}
                />
              </i>
              </div>,
            'error':
              <>
                {isLoggedIn &&
                  <div className="hit"><i>
                    <FormattedMessage
                      id="Search.FehlerBeliebig"
                      values={{ts: Date.now()}}
                    />
                  </i>
                  </div>
                }
              </>,
          }[recommendationsRequest.status]}
        </div>
      </form>
    </>
  );
};

export default Search;