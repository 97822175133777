import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {userActions, userSelectors} from 'features/userSlice';
import {ReactComponent as HeartIcon} from 'components/icons/heart.svg';
import {ReactComponent as HeartFullIcon} from 'components/icons/heart-full.svg';
import {searchApi} from 'api/favoritesApi';
import LoadingBottle from './LoadingBottle';

const FavoritesHeart = ({
  wineId,
}) => {
  const dispatch = useDispatch();

  const [isFavorite, setIsFavorite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const soldTo = useSelector(userSelectors.soldTo);
  const favorites = useSelector(userSelectors.favorites);
  const favoritesRequest = useSelector(userSelectors.favoritesRequest);
  useEffect(() => {
    if (soldTo != null && favoritesRequest.status === 'idle') {
      dispatch(userActions.fetchFavorites({soldTo: soldTo}));
    }
  }, [soldTo, favoritesRequest.status]);

  useEffect(() => {
    if (favorites && favorites.length > 0) {
      const result = favorites.filter(f => f === wineId);

      setIsFavorite(result.length > 0);
    } else {
      setIsFavorite(false);
    }
  }, [favorites]);

  const handleClick = () => {
    setIsLoading(true);

    if (isFavorite) {
      searchApi.removeFavorite(wineId, soldTo).then(() => {
        dispatch(userActions.fetchFavorites({soldTo: soldTo})).then(() => {
          setIsLoading(false);
        });

      });
    } else {
      searchApi.addFavorite(wineId, soldTo).then(() => {
        dispatch(userActions.fetchFavorites({soldTo: soldTo})).then(() => {
          setIsLoading(false);
        });
      });
    }
  };

  return (
    <div className="pointer-cursor" style={{color: '#AF1430', height: '24px'}}
      onClick={handleClick}> {/* TODO: ev. this color: #990040 */}
      {favorites === null || isLoading ?
        <LoadingBottle/>
        : isFavorite ?
          <HeartFullIcon
            onClick={(ev) => {
              ev.preventDefault();
            }}
          />
          :
          <HeartIcon
            onClick={(ev) => {
              ev.preventDefault();
            }}
          />
      }
    </div>
  );
};

FavoritesHeart.propTypes = {
  wineId: PropTypes.string,
};

export default FavoritesHeart;