import axios from 'axios';
import {identity, mergeDeepRight, path} from 'ramda';
import {authActions, authSelectors} from '../features/authSlice';

const wineAppBaseUrl = process.env.REACT_APP_WINE_APP_BACKEND_BASE_URL;

const publicClient = axios.create({
  baseURL: wineAppBaseUrl
});

const authedClient = axios.create({
  baseURL: wineAppBaseUrl
});

/**
 * we can't just import redux store here because that creates circular dependencies.
 *
 * -> needs to be initialized in index.js after store is created for the first time.
 */
export function initAuthedWineAppClient(reduxStore) {
  authedClient.interceptors.request.use(
    config => {
      const accessToken = authSelectors.accessToken(reduxStore.getState());
      if (!accessToken) {
        return Promise.reject('no access token');
      }
      return mergeDeepRight(config, {headers: {'Authorization': `Bearer ${accessToken}`}});
    }
  );

  const CUSTOM_RETRY_MARKER = '_waRetried';
  const RETRYABLE_STATUS_CODES = new Set([401, 403]);
  authedClient.interceptors.response.use(
    identity,
    failedRequest => {
      const retryableStatusCode = RETRYABLE_STATUS_CODES.has(path(['response', 'status'], failedRequest));
      const alreadyRetried = path(['config', CUSTOM_RETRY_MARKER], failedRequest);
      if (!retryableStatusCode || alreadyRetried) {
        return Promise.reject(failedRequest);
      }

      return reduxStore.dispatch(authActions.refresh())
        .then(() => {
          const newConfig = Object.assign({}, failedRequest.response.config, {CUSTOM_RETRY_MARKER: true});
          return authedClient.request(newConfig);
        });
    }
  );
}





export {publicClient as wineAppPublicClient};
export {authedClient as wineAppAuthedClient};