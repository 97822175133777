import React from 'react';
import PropTypes from 'prop-types';

import {ReactComponent as IconThinCircle} from 'components/icons/thin-circle.svg';
import {ReactComponent as IconGlobe} from 'components/icons/globe.svg';
import {ReactComponent as IconHouse} from 'components/icons/house.svg';
import {ReactComponent as IconCircle} from 'components/icons/circle.svg';
import {ReactComponent as IconGrapes} from 'components/icons/grapes.svg';
import {ReactComponent as IconGlass} from 'components/icons/glass.svg';
import {ReactComponent as IconTaste} from 'components/icons/taste.svg';
import {ReactComponent as IconPercentage} from 'components/icons/percentage.svg';
import {ReactComponent as IconBottle} from 'components/icons/bottle.svg';
import {ReactComponent as IconCork} from 'components/icons/cork.svg';
import {ReactComponent as IconThermometer} from 'components/icons/thermometer.svg';
import {ReactComponent as IconCalendar} from 'components/icons/calendar.svg';
import {ReactComponent as IconAward} from 'components/icons/award.svg';
import {ReactComponent as IconForkKnife} from 'components/icons/fork-knife.svg';
import {ReactComponent as IconContainer} from 'components/icons/container.svg';
import {ReactComponent as IconAppellation} from 'components/icons/appellation.svg';


import {FormattedMessage} from 'react-intl';


export function PDF(props) {
  if (props.product == null) {
    return null;
  }

  const product = props.product;
  const language = props.language;

  const infos = [
    {
      show: true,
      icon: <IconThinCircle />,
      title: <FormattedMessage
        id="ProductDetailPage.ArtNr"
      />,
      content: product?.objectID,
    },
    {
      show: true,
      icon: <IconGlobe />,
      title: <FormattedMessage
        id="ProductDetailPage.region"
      />,
      content: product?.region,
    },
    {
      show: true,
      icon: <IconAppellation />,
      title: <FormattedMessage
        id="ProductDetailPage.Qualitätsbezeichnung"
      />,
      content: product?.appellation,
    },
    {
      show: true,
      icon: <IconHouse />,
      title: <FormattedMessage
        id="ProductDetailPage.produzent"
      />,
      content: product?.producer,
    },
    {
      show: true,
      icon: <IconCircle style={{
        color: {
          Rotwein: '#AF1430',
          Weisswein: '#F8E592',
          Rosé: '#DFA67E',
          Schaumwein: '#D3D3D3',
          Süsswein: '#F1D4BE',
        }[product.category]}} />,
      title: <FormattedMessage
        id="ProductDetailPage.weintyp"
      />,
      content: product?.category,
    },
    {
      show: true,
      icon: <IconGrapes />,
      title: <FormattedMessage
        id="ProductDetailPage.rebsorte"
      />,
      content: <>{product.grapes &&
        <span className="value whitespace-pre">
          {product.grapes.text?.replaceAll(', ', '\n') ?? ''}
        </span>}
      </>,
    },
    {
      show: true,
      icon: <IconGlass />,
      title: <FormattedMessage
        id="ProductDetailPage.Süssegrad"
      />,
      content: <><Dots
        active={product.sweetness}
        lower={<FormattedMessage
          id="ProductDetailPage.trocken"
        />}
        upper={<FormattedMessage
          id="ProductDetailPage.lieblich"
        />}
      /></>,
    },
    {
      show: true,
      icon: <IconTaste />,
      title: <FormattedMessage
        id="ProductDetailPage.Schweregrad"
      />,
      content: <Dots
        active={product.taste}
        lower={<FormattedMessage
          id="ProductDetailPage.leicht"
        />}
        upper={<FormattedMessage
          id="ProductDetailPage.schwer"
        />}
      />,
    },
    {
      show: true,
      icon: <IconPercentage />,
      title: <FormattedMessage
        id="ProductDetailPage.alkoholgehalt"
      />,
      content: `${product?.alcoholContent}% vol`,
    },
    {
      show: true,
      icon: <IconBottle />,
      title: <FormattedMessage
        id="ProductDetailPage.flaschengrösse"
      />,
      content: `${product?.bottle_size}cl`,
    },
    {
      show: true,
      icon: <IconCork />,
      title: <FormattedMessage
        id="ProductDetailPage.verschluss"
      />,
      content:  <span className="value">{{
        de: product.closure,
        fr: product.closureFr,
        it: product.closureIt
      }[language]}</span>,
    },
    {
      show: true,
      icon: <IconThermometer />,
      title: <FormattedMessage
        id="ProductDetailPage.trinktemperatur"
      />,
      content: <>{product.drinking_temperature &&
        <>
          {product.drinking_temperature[0]}° - {product.drinking_temperature[1]}°C
        </>
      }</>,
    },
    {
      show: true,
      icon: <IconCalendar />,
      title: <FormattedMessage
        id="ProductDetailPage.jahrgang"
      />,
      content: `${product?.year}`,
    },
    {
      show: product?.ageingContainer,
      icon: <IconContainer />,
      title: <FormattedMessage
        id="ProductDetailPage.Lagerung"
      />,
      content: <>
        <FormattedMessage id="ProductDetailPage.Lagerung.Eichenfass"/>
        {product.ageingTime && (<>
          <br />{product.ageingTime} <FormattedMessage id="ProductDetailPage.Lagerzeit.Monate"/>
        </>)}
      </>,
    },
    {
      show: product.awards && product.awards?.length > 0,
      icon: <IconAward />,
      title: <FormattedMessage
        id="ProductDetailPage.Award"
      />,
      content: product.awards && product.awards?.length > 0 && <>{{
        'de': <>{product.awards.map(item => <>{item}<br /></>) || '-'}</>,
        'fr': <>{product.awardsFr.map(item => <>{item}<br /></>) || '-'}</>,
        'it': <>{product.awardsIt.map(item => <>{item}<br /></>) || '-'}</>,
      }[language]}</>,
    },
    {
      show: true,
      icon: <IconForkKnife />,
      title: <FormattedMessage
        id="ProductDetailPage.PasstZu"
      />,
      content: <>{product?.suitableFood && <>{{
        'de': <>{product.suitableFood.map(item => <>{item}<br /></>) || '-'}</>,
        'fr': <>{product.suitableFood.map(item => <>{item}<br /></>) || '-'}</>,
        'it': <>{product.suitableFood.map(item => <>{item}<br /></>) || '-'}</>,
      }[language]}</>}</>,
    },
  ];

  return (
    <div className="w-a4-width h-a4-height p-[18mm] text-[#222]">
      <h1 className="text-[20pt] font-bold">
        {{
          de: product.name,
          fr: product.nameFr,
          it: product.nameIt,
        }[language]}
      </h1>
      <hr className="border-[#222] mt-[5mm] mb-[5mm]" />
      <div className="flex gap-[18mm]">
        <div className="w-[100mm] flex justify-center items-center overflow-hidden">
          <img
            className="w-[70mm] h-[150mm] object-cover"
            src={product.img_url}
          />
        </div>
        <div>
          <p className="text-[14pt]">{{
            de: product.description,
            fr: product.descriptionFr,
            it: product.descriptionIt
          }[language]}</p>

          {product.regular_price_per_bottle && product.regular_price_per_unit && <div className="my-[8mm]">
            <div className="text-[13pt]">
              CHF {product.regular_price_per_bottle.toFixed(2)} / {product.bottle_size}cl
            </div>
            <div className="text-[12pt] text-[#888]">
              <FormattedMessage id="Product.DeliveryUnit"/>:&nbsp;
              {{
                de: product.delivery_unit,
                fr: product.delivery_unitFr,
                it: product.delivery_unitIt,
              }[language]},
              CHF {product.regular_price_per_unit.toFixed(2)}
            </div>
          </div>}

          <table className="text-[11pt] font-light">
            {infos.map((item, key) => item.show && <tr key={key} className="">
              <td className="align-top pb-[5mm] pr-[5mm] text-[#888]">{item.icon}</td>
              <td className="align-top pb-[5mm] pr-[15mm] text-[#888]">{item.title}</td>
              <td className="align-top pb-[5mm]">{item.content}</td>
            </tr>)}
          </table>
        </div>
      </div>
    </div>
  );
}

PDF.propTypes = {
  product: PropTypes.any,
  language:PropTypes.string,
};

function Dots(props) {
  return <div className="w-[150px]">
    <div className="flex justify-between gap-2 my-1">
      {[1,2,3,4,5,6,7,8,9,10].map((value, index) => (
        <div
          key={index}
          className={`w-2 h-2 rounded-full ${parseInt(props.active) === parseInt(value) ? 'bg-[#AF1430]' : 'bg-[#eee]'}`}
        ></div>
      ))}
    </div>
    <div className="flex justify-between text-[8pt]">
      <div>
        {props.lower}
      </div>
      <div>
        {props.upper}
      </div>
    </div>
  </div>;
}

Dots.propTypes = {
  active: PropTypes.any,
  lower: PropTypes.any,
  upper: PropTypes.any,
};