import GetPdfTemplate from './GetPdfTemplate';
import {generatePdf} from 'api/pdfGenerator';

export function generateWineMenuPdf(product, language) {
  return generatePdf(toPdfRequestData(product, language));
}

export function toPdfRequestData(product, language) {
  const template = GetPdfTemplate(product, language);
  return {
    format: 'a4',
    printMode: 'singlepage',
    template: template,
    data: {}
  };
}