import React from 'react';

import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';

import App from './App';
import {initAuthedWineAppClient} from './api/wineAppClient';
import {initAuthedFsCockpitClient} from './api/fsCockpitClient';
import {initAuthedPdfGeneratorClient} from './api/pdfGeneratorClient';
import reportWebVitals from './reportWebVitals';
import store from './store';

import './index.scss';
import {initZenDesk} from './libs/zenDesk';

import SimpleReactLightbox from 'simple-react-lightbox';

initAuthedWineAppClient(store);

initAuthedFsCockpitClient(store);

initAuthedPdfGeneratorClient(store);

initZenDesk({
  webWidget: {
    color: {
      theme: '#AF1430'
    },
    zIndex: 1000
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
