import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connectHits } from 'react-instantsearch-dom';
// import ProductCard from '../general/ProductCard.js';
import { ReactComponent as IconSmallArrowLeft } from 'components/icons/small-arrow-left.svg';

const Hits = ({
  hits,
  className = '',
  style = {},
  title = null,
  hitComponent,
  doNotShowOnEmpty = false,
}) => {
  const Hit = hitComponent;
  const hsw = useRef();

  if(doNotShowOnEmpty && hits.length == 0) return (<></>);

  return (
    <>
      {title && <h2>{title}</h2>}
      <div ref={hsw} className={className ? className : 'row'} style={style}>
        {hits.length >= 1 ? hits.map(hit => (
          // <div className="col-xs-12 col-md-6 col-lg-6 col-xl-4 my-3" key={hit.objectID}>
          //   <ProductCard product={hit} shadow />
          // </div>
          <Hit key={hit.objectID} hit={hit} />
        )) : <div className="d-flex justify-content-center my-5">keine passenden Weine gefunden</div>}
      </div>

      {className.includes('hsw') && hits.length > 1 && <div className="showMore" onClick={() => hsw.current.scrollBy({
        top: 0,
        left: 300,
        behavior: 'smooth'
      })}>
        <button>
          <IconSmallArrowLeft />
        </button>
      </div>}
    </>
  );
};

Hits.propTypes = {
  hits: PropTypes.array.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  hitComponent: PropTypes.any,
  title: PropTypes.string,
  doNotShowOnEmpty: PropTypes.bool,
};

export const CustomHits = connectHits(Hits);