import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {algoliaCredentialsApi} from 'api/algoliaCredentialsApi';

const selectors = {
  appId: state => state.algoliaCredentials.appId,
  apiKey: state => state.algoliaCredentials.apiKey,
  productIndex: state => state.algoliaCredentials.productIndex,
  fetchLoading: state => state.algoliaCredentials.requests.fetchCredentials.loading,
  fetchError: state => state.algoliaCredentials.requests.fetchCredentials.error
};

const fetchCredentials = createAsyncThunk(
  'algolia/fetchCredentials',
  (loggedIn, thunkAPI) => {
    const state = thunkAPI.getState();
    const error = selectors.fetchError(state);
    if (error) {
      return Promise.reject(error);
    }
    return algoliaCredentialsApi.getAlgoliaCredentials(loggedIn);
  }
);

const {actions, reducer} = createSlice({
  name: 'algoliaCredentials',
  initialState: {
    appId: null,
    apiKey: null,
    productIndex: null,
    requests: {
      fetchCredentials: {
        loading: false,
        error: null
      }
    }
  },
  reducers: {
    clear: (state) => {
      state.appId = null;
      state.apiKey = null;
      state.productIndex = null;
    }
  },
  extraReducers: {
    [fetchCredentials.pending]: state => {
      state.requests.fetchCredentials.loading = true;
      state.requests.fetchCredentials.error = null;
    },
    [fetchCredentials.fulfilled]: (state, action) => {
      state.requests.fetchCredentials.loading = false;
      state.requests.fetchCredentials.error = null;
      state.appId = action.payload.appId;
      state.apiKey = action.payload.apiKey;
      state.productIndex = action.payload.productIndex;
    },
    [fetchCredentials.rejected]: (state, action) => {
      state.requests.fetchCredentials.loading = false;
      state.requests.fetchCredentials.error = action.error.message;
    }
  }
});

const publicActions = {
  clear: actions.clear,
  fetchCredentials
};

export {publicActions as algoliaCredentialsActions};

export {selectors as algoliaCredentialsSelectors};

export {reducer as algoliaCredentialsReducer};
