import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconClose } from 'components/icons/close.svg';

const alertStyle = {
  backgroundColor: '#990040',
  color: 'white',
  padding: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  width: '350px',
  maxWidth: '100%',
  boxSizing: 'border-box'
};

const buttonStyle = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#FFFFFF'
};

const AlertTemplate = ({
  message,
  options,
  style,
  close,
}) => {
  return (
    <div style={{ ...alertStyle, ...style }}>
      {options.type === 'info' && <></>}
      {options.type === 'success' && <></>}
      {options.type === 'error' && <>Fehler: </>}
      {message}
      <button onClick={close} style={buttonStyle}>
        <IconClose />
      </button>
    </div>
  );
};

AlertTemplate.propTypes = {
  style: PropTypes.object,
  options: PropTypes.object,
  message: PropTypes.string,
  close: PropTypes.func,
};

export default AlertTemplate;