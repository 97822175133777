import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {searchApi} from 'api/searchApi';
import { getRequestStatus } from 'helper/requestStatus';

export const searchSelectors = {
  query: state => state.search.query,
  history: state => state.search.history,
  historyNeedsRefresh: state => state.search.historyNeedsRefresh,
  historyRequest: state => getRequestStatus(
    state.search.requests.fetchHistory,
    state.search.history !== null,
  ),
  recommendations: state => state.search.recommendations,
  recommendationsRequest: state => getRequestStatus(
    state.search.requests.fetchRecommendations,
    state.search.recommendations !== null,
  ),
  addToHistoryRequest: state => getRequestStatus(
    state.search.requests.addToHistory,
    state.search.historyNeedsRefresh,
  ),
  deleteFromHistoryRequest: state => getRequestStatus(
    state.search.requests.deleteFromHistory,
    state.search.historyNeedsRefresh,
  ),
};

export const fetchHistory = createAsyncThunk(
  'search/fetchHistory',
  async () => searchApi.history()
);

export const addToHistory = createAsyncThunk(
  'search/addToHistory',
  async (body) => searchApi.addToHistory(body.query)
);

export const deleteFromHistory = createAsyncThunk(
  'search/deleteFromHistory',
  async (body) => searchApi.deleteFromHistory(body.query)
);

export const fetchRecommendations = createAsyncThunk(
  'search/fetchRecommendations',
  async (body) => searchApi.recommendations(body.isLoggedIn)
);

const initialState = {
  query: '',
  history: null,
  historyNeedsRefresh: false,
  recommendations: null,
  requests: {
    fetchHistory: {
      loading: false,
      error: null,
    },
    addToHistory: {
      loading: false,
      error: null,
    },
    deleteFromHistory: {
      loading: false,
      error: null,
    },
    fetchRecommendations: {
      loading: false,
      error: null,
    },
  },
};

export const searchSlice = createSlice({
  name: 'search',
  initialState: initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload.query;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [fetchHistory.pending]: (state) => {
      state.requests.fetchHistory.loading = true;
      state.requests.fetchHistory.error = null;
    },
    [fetchHistory.fulfilled]: (state, action) => {
      state.requests.fetchHistory.loading = false;
      state.requests.fetchHistory.error = null;
      state.history = action.payload.entries;
      state.historyNeedsRefresh = false;
    },
    [fetchHistory.rejected]: (state, action) => {
      state.requests.fetchHistory.loading = false;
      state.requests.fetchHistory.error = action.error.message;
    },
    [addToHistory.pending]: (state) => {
      state.requests.addToHistory.loading = true;
      state.requests.addToHistory.error = null;
    },
    [addToHistory.fulfilled]: (state) => {
      state.requests.addToHistory.loading = false;
      state.requests.addToHistory.error = null;
      state.historyNeedsRefresh = true;
    },
    [addToHistory.rejected]: (state, action) => {
      state.requests.addToHistory.loading = false;
      state.requests.addToHistory.error = action.error.message;
    },
    [deleteFromHistory.pending]: (state) => {
      state.requests.deleteFromHistory.loading = true;
      state.requests.deleteFromHistory.error = null;
    },
    [deleteFromHistory.fulfilled]: (state) => {
      state.requests.deleteFromHistory.loading = false;
      state.requests.deleteFromHistory.error = null;
      state.historyNeedsRefresh = true;
    },
    [deleteFromHistory.rejected]: (state, action) => {
      state.requests.deleteFromHistory.loading = false;
      state.requests.deleteFromHistory.error = action.error.message;
    },
    [fetchRecommendations.pending]: (state) => {
      state.requests.fetchRecommendations.loading = true;
      state.requests.fetchRecommendations.error = null;
    },
    [fetchRecommendations.fulfilled]: (state, action) => {
      state.requests.fetchRecommendations.loading = false;
      state.requests.fetchRecommendations.error = null;
      state.recommendations = action.payload.entries;
    },
    [fetchRecommendations.rejected]: (state, action) => {
      state.requests.fetchRecommendations.loading = false;
      state.requests.fetchRecommendations.error = action.error.message;
    },
  },
});

export const { setQuery, reset } = searchSlice.actions;

export default searchSlice.reducer;
