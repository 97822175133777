import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, userSelectors } from 'features/userSlice';
import LoadingBottle from 'components/general/LoadingBottle';
import {FormattedMessage} from 'react-intl';
import { cartActions } from 'features/cartSlice';
import {authActions, authSelectors} from 'features/authSlice';
import { recommendationActions } from 'features/recommendationSlice';
import {reset} from 'features/searchSlice';
import { ReactComponent as IconSmallArrowLeft } from 'components/icons/small-arrow-left.svg';
import './OutletSwitcherList.scss';
//import {checkCustomerInput} from '../../features/choseCustomerSlice';


export function OutletSwitcherList({
  className,
  onChange = null,
}) {
  const dispatch = useDispatch();

  const outlets = useSelector(userSelectors.outlets);
  const outletsRequest = useSelector(userSelectors.outletsRequest);
  const isSales = useSelector(authSelectors.isSalesUser);
  //const soldTo = useSelector(userSelectors.soldTo);

  useEffect(() => {
    if (outletsRequest.status === 'idle' && !isSales) {
      dispatch(userActions.fetchOutlets());
    }
  });

  const handleSetOutlet = (outletId) => {
    onChange && onChange();
    dispatch(userActions.setShipTo(null));
    dispatch(userActions.setSoldTo(outletId));
    // dispatch(userActions.refresh());
    dispatch(userActions.fetchShipTos({soldTo: outletId}));
    dispatch(cartActions.resetDeliveryDates());
    dispatch(userActions.refreshFavorites());
  };

  const handleLogout = () => {
    dispatch(authActions.logout());
    dispatch(userActions.logout());
    dispatch(reset());
    dispatch(recommendationActions.resetPlz());
  };

  return (
    <div className={className}>
      {{
        'idle':
          <div className="outlet-list-item loading">
            <LoadingBottle /> 
            <FormattedMessage
              id="OutletSwitcher.Loading"
            />
          </div>,
        'loading': 
          <div className="outlet-list-item loading">
            <LoadingBottle /> 
            <FormattedMessage
              id="OutletSwitcher.Loading"
            />
          </div>,
        'success':
          outlets?.length > 0 ? outlets?.map(outlet => (
            <div key={outlet.id} className="outlet-list-item cursor-pointer" onClick={() => handleSetOutlet(outlet.id)}>
              <b>
                {outlet.name},<br />
                {outlet.street}, {outlet.zip} {outlet.city}
              </b>
              <IconSmallArrowLeft />
            </div>
          )) : <div className="outlet-list-item text-center">
            <div className="mb-4">
              Der Webshop ist nicht für sie freigegeben. Sie können die Weine jedoch trotzdem ansehen, wenn sie sich ausloggen.
            </div>

            <button className="btn btn-primary" onClick={() => handleLogout()}>
              Abmelden
            </button>
          </div>,
        'error':
          <div className="outlet" onClick={() => dispatch(userActions.fetchOutlets())}>
            <FormattedMessage
              id="OutletSwitcher.Error"
              values={{
                msg: outletsRequest.msg
              }}
            />
          </div>,
      }[outletsRequest.status]}
    </div>
  );
}

OutletSwitcherList.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default OutletSwitcherList;