import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../features/authSlice';
import OutletSwitcher from 'components/general/OutletSwitcher';
import Modal from 'components/general/Modal';
import { userActions, userSelectors } from 'features/userSlice';
import { ReactComponent as IconArrowLeft } from 'components/icons/arrow-left.svg';

export function OutletSwitcherModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const soldTo = useSelector(userSelectors.soldTo);
  const soldToPrev = useSelector(userSelectors.soldToPrev);
  const shipToPrev = useSelector(userSelectors.shipToPrev);
  const isSales = useSelector(authSelectors.isSalesUser);

  useEffect(() => {
    setOpen(isLoggedIn && isSales === false && soldTo == null);
  }, [isLoggedIn, soldTo, open]);

  const onCancel = () => {
    dispatch(userActions.setSoldTo(soldToPrev));
    dispatch(userActions.setShipTo(shipToPrev));
  };

  return (
    <>
      <Modal
        name="outlet switcher"
        open={open}
        setOpen={setOpen}
        closeable={false}
        position="middle-side"
        title={soldToPrev && <div className="cursor-pointer" onClick={() => onCancel()}>
          <IconArrowLeft className="me-3" />
          Zurück
        </div>}
        body={<OutletSwitcher />}
      />
    </>
  );
}

export default OutletSwitcherModal;