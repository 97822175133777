import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {recommendationApi} from 'api/recommendationApi';
import {getRequestStatus} from 'helper/requestStatus';

const fetchSearchResults = createAsyncThunk(
  'recommendation/fetchSearch',
  async (body) => recommendationApi.fetchSearch(body.query)
);

export const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState: {
    active: false,
    plz: null,
    plzInfo: null,
    price: null,
    type: null,
    searchResults: null,
    requests: {
      fetchSearchResults: {
        loading: false,
        error: null,
      },
    },
  },
  reducers: {
    setActive: (state) => {
      state.active = true;
    },
    setPrice: (state, action) => {
      state.price = action.payload.price;
    },
    setPlz: (state, action) => {
      state.plz = action.payload.plz;
      state.plzInfo = action.payload.plzInfo;
    },
    resetPlz: (state) => {
      state.active = false;
      state.plz = null;
      state.plzInfo = null;
    },
    resetSearch: (state) => {
      state.searchResults = null;
      state.requests.fetchSearchResults.loading = false;
      state.requests.fetchSearchResults.error = null;
    },
  },
  extraReducers: {
    [fetchSearchResults.pending]: (state) => {
      state.requests.fetchSearchResults.loading = true;
      state.requests.fetchSearchResults.error = null;
    },
    [fetchSearchResults.fulfilled]: (state, action) => {
      state.requests.fetchSearchResults.loading = false;
      state.requests.fetchSearchResults.error = null;
      state.searchResults = action.payload;
    },
    [fetchSearchResults.rejected]: (state, action) => {
      state.requests.fetchSearchResults.loading = false;
      state.requests.fetchSearchResults.error = action.error.message;
    },
  },
});

export const recommendationSelectors = {
  recommendationActive: state => state.recommendation.active,
  plz: state => state.recommendation.plz,
  plzInfo: state => state.recommendation.plzInfo,
  searchResults: state => state.recommendation.searchResults,
  searchResultsRequest: state => getRequestStatus(
    state.recommendation.requests.fetchSearchResults,
    state.recommendation.searchResults !== null,
  ),
};
const actions = {
  ...recommendationSlice.actions,
  fetchSearchResults,
};
export {actions as recommendationActions};

export default recommendationSlice.reducer;