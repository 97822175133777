import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {simmilarApi} from 'api/simmilarApi';
import { getRequestStatus } from 'helper/requestStatus';

const getSimmilarWines = createAsyncThunk(
  'simmilar/getSimmilarWines',
  async (body) => {
    if(body.authed) return simmilarApi.getSimmilarWinesAuthed(body.ids);
    else return simmilarApi.getSimmilarWinesPublic(body.ids);
  }
);

const simmilarSlice = createSlice({
  name: 'simmilar',
  initialState: {
    simmilars: null,
    requests: {
      getSimmilarWines: {
        loading: false,
        error: null,
      },
    },
  },
  reducers: {
    reset: (state) => {
      state.requests.getSimmilarWines.loading = false;
      state.requests.getSimmilarWines.error = null;
      state.simmilars = null;
    },
  },
  extraReducers: {
    [getSimmilarWines.pending]: (state) => {
      state.requests.getSimmilarWines.loading = true;
      state.requests.getSimmilarWines.error = null;
      state.simmilars = null;
    },
    [getSimmilarWines.fulfilled]: (state, action) => {
      state.requests.getSimmilarWines.loading = false;
      state.requests.getSimmilarWines.error = null;
      // TODO: Remove unique once the API works correctly...
      state.simmilars = action.payload.map(item => item && item.id ? item.id : null).filter((v, i, a) => a.indexOf(v) === i);
    },
    [getSimmilarWines.rejected]: (state, action) => {
      state.requests.getSimmilarWines.loading = false;
      state.requests.getSimmilarWines.error = action.error.message;
      state.simmilars = null;
    },
  },
});

export const simmilarSelectors = {
  simmilars: state => state.simmilar.simmilars,
  simmilarsRequest: state => getRequestStatus(
    state.simmilar.requests.getSimmilarWines,
    state.simmilar.simmilars !== null,
  ),
};

const actions = {
  ...simmilarSlice.actions,
  getSimmilarWines,
};
export {actions as simmilarActions};

export default simmilarSlice.reducer;