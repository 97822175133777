import React from 'react';
import {NavLink} from 'react-router-dom';
import {ReactComponent as IconHome} from 'components/icons/home.svg';
import {ReactComponent as IconStar} from 'components/icons/star.svg';
import {ReactComponent as PercentageBig} from 'components/icons/percentage-big.svg';
import {ReactComponent as IconBottles} from 'components/icons/bottles.svg';
import {CustomZenDeskButton} from './CustomZenDeskButton';
import './BottomBar.scss';
import MediaQuery from 'react-responsive';
import {breakpoints} from 'helper/responsive-breakpoints';
import { authSelectors } from 'features/authSlice';
import { useSelector } from 'react-redux';

const BottomBar = () => {
  const isLoggedIn = useSelector(authSelectors.loggedIn);

  return(
    <nav className={`bottom-bar d-block d-md-none ${isLoggedIn && 'isLoggedIn'}`}>
      <div className="row">
        <div className="col">
          <NavLink to="/" exact>
            <IconHome />
            <span>Home</span>
          </NavLink>
        </div>
        <div className="col">
          <NavLink to="/products/all">
            <IconBottles />
            <span>Sortiment</span>
          </NavLink>
        </div>
        {isLoggedIn &&
        <div className="col">
          <NavLink to="/offers">
            <PercentageBig />
            <span className="text-center">
              Aktionen
            </span>
          </NavLink>
        </div>}
        <div className="col">
          <NavLink to="/collections">
            <IconStar />
            <span className="text-center">
              Kollektionen
            </span>
          </NavLink>
        </div>
        <div className="col">
          {/* don't load custom zen desk button if >= md to show default zen desk button on desktop */}
          <MediaQuery maxDeviceWidth={breakpoints.md}>
            <CustomZenDeskButton />
          </MediaQuery>
        </div>
      </div>
    </nav>
  );
};

export default BottomBar;