import {wineAppAuthedClient} from './wineAppClient';

function fetchSearch(query) {
  return wineAppAuthedClient.request({
    method: 'POST',
    url: '/region/search/',
    responseType: 'json',
    data: {
      query: query,
    }
  }).then(response => response.data);
}

export const recommendationApi = {
  fetchSearch,
};