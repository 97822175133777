import {authedPdfGeneratorClient} from './pdfGeneratorClient';

export function generatePdf(request) {
  return authedPdfGeneratorClient.request({
    method: 'POST',
    url: '/pdf',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    responseType: 'json',
    data: {
      format: request.format,
      printMode: request.printMode,
      template: request.template,
      data: request.data
    }
  });
}