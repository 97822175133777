import React from 'react';
import PropTypes from 'prop-types';
import { connectPagination } from 'react-instantsearch-dom';
import { ReactComponent as IconArrowLeft } from 'components/icons/arrow-left.svg';
// import { ReactComponent as IconDoubleArrowLeft } from 'components/icons/double-arrow-left.svg';
import {FormattedMessage} from 'react-intl';

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
  <nav>
    {/* TODO: make this a bit more elegant pls */}
    <a
      className={currentRefinement === 1 ? 'btn btn-outline-primary disabled' : 'btn btn-outline-primary'}
      disabled={currentRefinement === 1}
      href={createURL(currentRefinement - 1)}
      onClick={event => {
        event.preventDefault();
        refine(currentRefinement - 1);
      }}
    >
      <IconArrowLeft />
      <span className="d-none d-sm-inline">
        &nbsp;&nbsp;
        <FormattedMessage
          id="CustomPagination.Zurück"
          values={{ts: Date.now()}}
        />
      </span>
    </a>

    {currentRefinement > 1 && <>
      <PageButton
        currentRefinement={currentRefinement}
        pageNr={1}
        nbPages={nbPages}
        refine={refine}
        createURL={createURL}
      />
      {currentRefinement - 1 > 1 && <span className='d-inline d-sm-none'>...</span>}
      {currentRefinement - 1 > 2 && <span className='d-none d-sm-inline'>...</span>}
    </>}

    {currentRefinement - 1 > 1 && <PageButton
      className='d-none d-sm-inline'
      currentRefinement={currentRefinement}
      pageNr={currentRefinement - 1}
      nbPages={nbPages}
      refine={refine}
      createURL={createURL}
    />}

    {<PageButton
      currentRefinement={currentRefinement}
      pageNr={currentRefinement}
      nbPages={nbPages}
      refine={refine}
      createURL={createURL}
    />}

    {currentRefinement + 1 < nbPages && <PageButton
      className='d-none d-sm-inline'
      currentRefinement={currentRefinement}
      pageNr={currentRefinement + 1}
      nbPages={nbPages}
      refine={refine}
      createURL={createURL}
    />}

    {currentRefinement < nbPages && <>
      {currentRefinement + 1 < nbPages && <span className='d-inline d-sm-none'>...</span>}
      {currentRefinement + 2 < nbPages && <span className='d-none d-sm-inline'>...</span>}
      <PageButton
        currentRefinement={currentRefinement}
        pageNr={nbPages}
        nbPages={nbPages}
        refine={refine}
        createURL={createURL}
      />
    </>}

    <a
      className={currentRefinement === nbPages ? 'btn btn-sm btn-outline-primary disabled' : 'btn btn-outline-primary'}
      disabled={currentRefinement === nbPages}
      href={createURL(currentRefinement + 1)}
      onClick={event => {
        event.preventDefault();
        refine(currentRefinement + 1);
      }}
    >
      <span className="d-none d-sm-inline">
        <FormattedMessage
          id="CustomPagination.Weiter"
          values={{ts: Date.now()}}
        />
        &nbsp;&nbsp;
      </span>
      <IconArrowLeft style={{transform: 'rotate(180deg)'}} /> 
    </a>
  </nav>
);

Pagination.propTypes = {
  currentRefinement: PropTypes.number,
  nbPages: PropTypes.number,
  refine: PropTypes.func,
  createURL: PropTypes.func,
};

export const CustomPagination = connectPagination(Pagination);

function PageButton({
  currentRefinement,
  pageNr,
  nbPages,
  createURL,
  refine,
  className,
}) {
  const active = pageNr === currentRefinement;
  const disabled = pageNr < 0 || pageNr > nbPages;
  const classNameActual = `btn btn-outline-primary mx-1 ${className} ${active ? 'active' : ''} ${disabled ? 'disabled' : ''}`;

  return (<>
    <a
      className={classNameActual}
      disabled={currentRefinement === pageNr}
      href={createURL(pageNr)}
      onClick={event => {
        event.preventDefault();
        refine(pageNr);
      }}
    >
      {pageNr}
    </a>
  </>);
}

PageButton.propTypes = {
  currentRefinement: PropTypes.number,
  nbPages: PropTypes.number,
  pageNr: PropTypes.number,
  refine: PropTypes.func,
  createURL: PropTypes.func,
  className: PropTypes.string,
};