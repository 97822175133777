/**
 * [Bootstrap breakpoints](https://getbootstrap.com/docs/5.0/layout/breakpoints/) as usable JS object
 */
export const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px'
};

/**
 * Breakpoints usable for [react-responsive](https://www.npmjs.com/package/react-responsive) `useMediaQuery()` calls
 */
export const mediaQueries = {
  sm: {query: `(min-width: ${breakpoints.sm})`},
  md: {query: `(min-width: ${breakpoints.md})`},
  lg: {query: `(min-width: ${breakpoints.lg})`},
  xl: {query: `(min-width: ${breakpoints.xl})`},
  xxl: {query: `(min-width: ${breakpoints.xl})`}
};
