import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {algoliaCredentialsActions, algoliaCredentialsSelectors} from 'features/algoliaCredentialsSlice';
import {initAlgoliaClient} from 'libs/algoliaClient';
import {authSelectors} from 'features/authSlice';

/**
 * Requests fetching of Algolia credentials, if no client is initialized,
 * and only renders children once the client is initialized properly.
 */
export function WithAlgolia(props) {
  const algoliaClient = useAlgoliaClient();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(authSelectors.loggedIn);
  useEffect(() => {
    dispatch(algoliaCredentialsActions.fetchCredentials(isLoggedIn));
  }, [dispatch, isLoggedIn]);

  return algoliaClient ? props.children : <></>;
}

WithAlgolia.propTypes = {
  children: PropTypes.node
};

export function useAlgoliaClient() {
  const [client, setClient] = useState(null);
  const appId = useSelector(algoliaCredentialsSelectors.appId);
  const apiKey = useSelector(algoliaCredentialsSelectors.apiKey);

  useEffect(() => {
    const c = initAlgoliaClient(appId, apiKey);
    setClient(c);
  }, [appId, apiKey]);

  return client;
}

export function useAlgoliaProductIndex() {
  const [productIndex, setProductIndex] = useState(null);
  const productIndexName = useSelector(algoliaCredentialsSelectors.productIndex);
  const client = useAlgoliaClient();

  useEffect(() => {
    if (client) {
      const pi = client.initIndex(productIndexName);
      setProductIndex(pi);
    }
  }, [client, productIndexName]);

  return productIndex;
}