import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ProductBadge from './ProductBadge';
import {
  Link
} from 'react-router-dom';
// import {authSelectors} from 'features/authSlice';
import AddToCart from 'components/general/Cart/AddToCart';
import { ReactComponent as Bottle } from 'components/icons/bottle.svg';
import './ProductCard.scss';
import { userSelectors } from 'features/userSlice';
// import {FormattedMessage} from 'react-intl';
import { ReactComponent as IconCart } from 'components/icons/cart.svg';
import { cartSelectors } from 'features/cartSlice';
import { authSelectors } from 'features/authSlice';
import FavoritesHeart from './FavoritesHeart';

const ProductCard = ({
  product,
  shadow = false,
  forceBorder = false,
}) => {
  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const isSales = useSelector(authSelectors.isSalesUser);
  const language = useSelector(userSelectors.language);
  const items = useSelector(cartSelectors.items);

  const [numberOfItems, setNumberOfItems] = useState(0);
  const dot = (a, b) => a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);

  useEffect(() => {
    if (items && items.length > 0) {
      const isProduct = items.map(item => item && item.wineId === product.objectID ? 1 : 0);
      const quantitys = items.map(item => item ? item.quantity : 0);

      setNumberOfItems(dot(isProduct, quantitys));
    } else {
      setNumberOfItems(0);
    }
  }, [items]);

  const detailPageLink = '/product/' + product.objectID;
  const flags = product.flags;

  const hasPrice = product.regular_price_per_unit != null && product.regular_price_per_bottle != null;

  const regularPricePerUnit = product.regular_price_per_unit;
  const salePricePerUnit = product.sale_price_per_unit || regularPricePerUnit;

  const pricePerBottleRegular = product.regular_price_per_bottle;
  const pricePerBottleSale = product.sale_price_per_bottle || pricePerBottleRegular;

  // const pricePerDl = pricePerBottle / parseFloat(product.bottle_size) * 10;

  const hasDiscount = Math.abs(regularPricePerUnit - salePricePerUnit) >= 0.01;
  const discountPercentage = Math.floor(100 * (regularPricePerUnit - salePricePerUnit) / regularPricePerUnit);

  return(
    <div className={`product ${shadow ? 'shadow-hover' : ''} ${forceBorder ? 'force-border' : ''}`}>
      <Link to={detailPageLink}>
        <div className="badge-wrapper">
          {/* {product.outOfStock && <ProductBadge type="outOfStock" />} */}
          {hasDiscount && <ProductBadge type="discount" text={`${discountPercentage}%`} />}
          {product.alcoholContent < 1 && <ProductBadge type="alcoholFree" />}
          {flags && flags.length > 0 ?
            flags.map((flag, index) => index < 2 - product.outOfStock - hasDiscount - (product.alcoholContent < 1) && (
              (flag !== 'nonExclusive' && flag !== 'alcoholFree') && <ProductBadge key={index} type={flag} />
            )) : <></>
          }
          {(isLoggedIn) &&
            <div className="position-absolute top-0 end-0 bg-white p-1">
              <FavoritesHeart wineId={product.objectID} />
            </div>
          }
        </div>
        <div className="top-wrapper">
          <div className="image-wrapper">
            {product.img_url ?
              <div
                style={{backgroundImage: `url(${product.img_url})`}}
                className="preview-image">
              </div>
              :
              <div className="preview-image">
                <Bottle />
              </div>
            }
          </div>
          <div className="text-wrapper">
            <div className="title-wrapper">
              <div className='art-nr'>
                {isSales ? <>
                  Art.-Nr.: {product.objectID}
                </> : <>
                  &nbsp;
                </>}
              </div>
              <div className="title">
                {{
                  de: product.name,
                  fr: product.nameFr,
                  it: product.nameIt,
                }[language] || <i style={{fontWeight: 200}}></i>}
              </div>
            </div>
            <div className="text">
              {/* <div>
                {product.producer}&nbsp;
              </div> */}
              <div>
                {{
                  de: product.country,
                  fr: product.countryFr,
                  it: product.countryIt,
                }[language]}
                {{
                  de: product.country,
                  fr: product.countryFr,
                  it: product.countryIt,
                }[language] && <>,&nbsp;</>}
                {{
                  //
                  de: product.region,
                  fr: product.region,
                  it: product.region,
                }[language]}
              </div>
              <div>
                {{
                  de: product.category,
                  fr: product.categoryFr,
                  it: product.categoryIt,
                }[language]}&nbsp;
              </div>
            </div>
            {hasPrice && isLoggedIn &&
              <>
                <div className="price">
                  {hasDiscount ?
                    <>
                      <div className="now">
                        CHF {pricePerBottleSale.toFixed(2)} / {product.bottle_size}cl
                      </div>
                      <div className="normal">
                        CHF {pricePerBottleRegular.toFixed(2)} / {product.bottle_size}cl
                      </div>
                    </>
                    :
                    <>
                      <div className="now">
                        CHF {pricePerBottleRegular.toFixed(2)} / {product.bottle_size}cl
                      </div>
                    </>
                  }
                  <div className="delivery-unit">
                    {{
                      de: product.delivery_unit,
                      fr: product.delivery_unitFr,
                      it: product.delivery_unitIt,
                    }[language]},
                    CHF {salePricePerUnit.toFixed(2)}
                  </div>
                </div>
              </>
            }
          </div>
        </div>

        {isLoggedIn && hasPrice && <>
          <AddToCart
            productId={product.objectID}
            outOfStock={product.outOfStock}
            nextAvailabilityDate={product.nextAvailabilityDate}
            name={{
              de: product.name,
              fr: product.nameFr,
              it: product.nameIt,
            }[language]}
          />
          <div className="in-the-cart">
            {numberOfItems > 0 && <>
              <IconCart style={{height: '16px'}} />
              &nbsp;
              {numberOfItems}
              &times;
              {{
                de: product.delivery_unit,
                fr: product.delivery_unitFr,
                it: product.delivery_unitIt,
              }[language]}
              &nbsp;
              im Warenkorb
            </> || <>&nbsp;</>}
          </div>
        </>}
      </Link>
    </div>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  shadow: PropTypes.bool,
  forceBorder: PropTypes.bool,
};

export default ProductCard;